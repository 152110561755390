import _Vue from "vue";

export function MouseEventPolyfill(Vue: typeof _Vue, options?: MouseEventPolyfillOptions): void {
// polyfill for the mouse event https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
	(function (window) {
		try {
			new MouseEvent('test');
			return false; // No need to polyfill
		} catch (e) {
			// Need to polyfill - fall through
		}

		// Polyfills DOM4 MouseEvent
		var MouseEventPolyfill = function (eventType:any, params:any) {
			params = params || {bubbles: false, cancelable: false};
			var mouseEvent = document.createEvent('MouseEvent');
			mouseEvent.initMouseEvent(
				eventType,
				params.bubbles,
				params.cancelable,
				window,
				0,
				params.screenX || 0,
				params.screenY || 0,
				params.clientX || 0,
				params.clientY || 0,
				params.ctrlKey || false,
				params.altKey || false,
				params.shiftKey || false,
				params.metaKey || false,
				params.button || 0,
				params.relatedTarget || null
			);

			return mouseEvent;
		};

		MouseEventPolyfill.prototype = Event.prototype;

		// @ts-ignore
		window.MouseEvent = MouseEventPolyfill;
	})(window);
}

export class MouseEventPolyfillOptions {
}
