

import {Product} from '@/model/product';
import {GTMBroker} from '@/service/gtm-broker';
import {ProductCollection} from '@/service/product';
import Vue from 'vue';

interface HTMLElementWithStoredClickHandler extends HTMLElement {
    clickHandler?: (event: MouseEvent) => void
}

export default Vue.directive('gtm-product-click', {
    bind(element: HTMLElementWithStoredClickHandler, binding: any) {
        const {productKey, position, location, href} = binding.value;

        let originalEvent: MouseEvent | undefined;

        const callback = () => {

            if (originalEvent && href) {
                // if (originalEvent.ctrlKey || originalEvent.metaKey) {
                //     window.open(href, '_blank');
                // } else {
                //     window.location.assign(href);
                // }
                return;
            }

            const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });

            element.dispatchEvent(event);
        };
        let product: Product | null = null;


        element.clickHandler = (event: MouseEvent) => {
            if (!event.isTrusted || product == null) return;
            originalEvent = event;
            GTMBroker.getInstance().productClicked(product, callback, position || 1, location || '');
        };

        ProductCollection.getInstance().getProduct(productKey).then(productModel => {
            product = productModel;
            if (element.clickHandler) {
                element.addEventListener('click', element.clickHandler);
            }
        });
    },
    unbind(element: HTMLElementWithStoredClickHandler) {
        if (element.clickHandler) {
            element.removeEventListener('click', element.clickHandler);
        }
    }
});
