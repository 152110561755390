export class MessageBus {
	private static instance: MessageBus;
	private _subscriptions:any = {};
	private lastId:number = 0;

	static getInstance(): MessageBus {
		if (MessageBus.instance == null) {
			MessageBus.instance = new MessageBus();
		}
		return MessageBus.instance;
	}

	publish(eventType: string, args: any[]) {
		if (!this._subscriptions[eventType]) return;
		Object.keys(this._subscriptions[eventType]).forEach(key => this._subscriptions[eventType][key](args));
	};

	private _getNextUniqueId() {
		this.lastId += 1;
		return this.lastId;
	};

	subscribe(eventType: string, callback: (...args: any []) => void): { unsubscribe: () => void } {
		const id = this._getNextUniqueId();
		if (!this._subscriptions[eventType]) {
			this._subscriptions[eventType] = {};
		}
		this._subscriptions[eventType][id] = callback;

		return {
			unsubscribe: () => {
				delete this._subscriptions[eventType][id];
				if (Object.keys(this._subscriptions[eventType]).length === 0) {
					delete this._subscriptions[eventType];
				}
			},
		};
	};
}
