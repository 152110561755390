import _Vue from "vue";

//TODO: Rewrite jQuery
export function TableResponsive(Vue: typeof _Vue, options?: TableResponsiveOptions): void {
	$(function() {
		$('table.table').each(function() {
			$(this).wrap("<div class='table-responsive'></div>");
		});
	});
}

export class TableResponsiveOptions {
}
