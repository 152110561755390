
import Vue from "vue";

export default Vue.directive("anchor-scroll", {
	bind(element: any, binding: any) {
		const name = binding.value;
		element.href = "#" + name;

		element.clickListener = (event: MouseEvent) => {
			event.stopImmediatePropagation();
			event.preventDefault();

			const targetElement: HTMLElement | null = document.querySelector(`[name="${name}"]`);
			const headerElement = document.querySelector('header');
			if(targetElement == null || headerElement == null) return;

			const headerHeight = headerElement.offsetHeight;
			const scrollTop = targetElement.offsetTop - headerHeight;

			window.scrollTo({
				top: scrollTop,
				behavior: 'smooth'
			});
		};

		element.addEventListener("click", element.clickListener);
	},
	unbind(element: any) {
		element.removeEventListener("click", element.clickListener);
	}
});
