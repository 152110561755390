import { Util } from "@/util/util";

export class LocalStorage {
	private static instance: LocalStorage;
	private static className = "LocalStorage";

	private constructor() {
		// clear the entire storage when a different user has logged in.
		const currentCustomerNumber = Util.getConfig().customerNumber;
		if (this.get("$customerNumber") !== currentCustomerNumber) {
			console.debug("Customer number was changed, clearing localstorage", {
				location: LocalStorage.className
			});
			this.clear();
		}
		this.set("$customerNumber", currentCustomerNumber);
	}

	static getInstance(): LocalStorage {
		if (LocalStorage.instance == null) {
			LocalStorage.instance = new LocalStorage();
		}
		return LocalStorage.instance;
	}

	get(key: string): any {
		const encodedData = localStorage.getItem(key);
		if (encodedData == null || encodedData === 'null') {	// also do not try to parse string 'null'
			return null;
		}

		const decodedData = JSON.parse(encodedData);
		if (null == decodedData || typeof decodedData !== 'object') {	// check it to be a non-null object type
			return null;
		}

		if (decodedData.$expirationTime && decodedData.$expirationTime > -1 && (Date.now() > decodedData.$expirationTime)) {
			localStorage.removeItem(key);
			return null;
		}

		return (decodedData.value) ? decodedData.value : decodedData;
	}

	delete(key: string): void {
		localStorage.removeItem(key);
	}

	set(key: string, value: any, expirationTime = -1): void {
		const data = {
			$expirationTime: expirationTime,
			value: value
		};
		const json = JSON.stringify(data);
		localStorage.setItem(key, json);
	}

	/**
	 * Clears all the local storage except the currency code
	 */
	clear(): void {
		const currencyIsoCode = window.localStorage.getItem("currencyIsoCode");
		window.localStorage.clear();
		if (currencyIsoCode != null) {
			window.localStorage.setItem("currencyIsoCode", currencyIsoCode);
		}
	}
}
