import { FoundProducts } from "@/model/find-products-response";
import { Product, ProductMap } from "@/model/product";
import { Api } from "@/util/api";
import { GtmEnhancedEcommerceType } from "@/model/gtm-enhanced-ecommerce-type";
import {GTMBroker} from "@/service/gtm-broker";

export class ProductCollection {
	private static instance: ProductCollection;
	private products: ProductMap = new Map();

	private productRequests = new Map<string, Promise<Product>>();

	private constructor(private api: Api) {
	}

	static getInstance(): ProductCollection {
		if (ProductCollection.instance == null) {
			ProductCollection.instance = new ProductCollection(Api.getInstance());
		}
		return ProductCollection.instance;
	}

	getProduct(key: string): Promise<Product> {
		const existingProduct = this.products.get(key);
		if (existingProduct != null) {
			return Promise.resolve(existingProduct);
		}

		if (this.productRequests.has(key)) {
			return this.productRequests.get(key) as Promise<Product>;
		}

		if (!this.productRequests.has(key)) {
			const request: Promise<Product> = this.api.getProduct(key)
				.then((response: any) => {
					this.addProducts([response]);

					const product = this.products.get(key);
					if (product == null) {
						return Promise.reject("product not found");
					}
					return Promise.resolve(product);
				});
			this.productRequests.set(key, request);
		}

		return this.productRequests.get(key) as Promise<Product>;
	}

	/**
	 * Method which retrieves products based on a set of parameters (filters) from the products API endpoint.
	 */
	findProducts(searchParams: URLSearchParams, gtmEnhancedEcommerceType: string = GtmEnhancedEcommerceType.NONE): Promise<FoundProducts> {

		return this.api.findProducts(searchParams)
			.then((response: any) => {
				const foundProducts = response.reduce((aggregation: Product[],
					buResponse: { products: any }
				) => {
					return aggregation.concat(buResponse.products);
				}, []);
				this.addProducts(foundProducts);
				if (gtmEnhancedEcommerceType !== GtmEnhancedEcommerceType.NONE) {
					GTMBroker.getInstance().productsViewed(foundProducts, gtmEnhancedEcommerceType);
				}
				return Promise.resolve(new FoundProducts(response));
			});
	}

	private addProducts(products: any[]): void {
		const productsToAdd = products.map(data => {
			return new Product(data);
		});

		productsToAdd.forEach(product => {
			this.products.set(product.getKey(), product);
		});
	}
}