import _Vue from "vue";
import { Util } from "@/util/util";

//TODO: Rewrite jQuery
export function Navigation(
	Vue: typeof _Vue,
	options?: NavigationOptions
): void {
	const _navigation = {
		parentElement: "nav.nav",
		toggleButton: ".toggle-nav",
		toggleSubNavButton: ".toggle-subnav",
		toggleSubNavDesktop: ".toggle-subnav-desktop",
		subnavMobile: ".subnav-md",
		hasIcon: ".has-icon",
		$bodyEl: $("body"),
		v2ToggleButton: ".btn_nav",
		v2CloseIcon: ".close-icon",
		v2Header: ".header-v2",
		v2Subnav: ".nav__dropdown",
		v2SiteSwitch: ".site-switcher",

		created: function() {
			if (Util.isDesktop() && Util.isHomePage()) {
				this.$bodyEl.addClass("init-large");
				this.openMenu();
			}
		},

		mounted: function() {
			$(_navigation.toggleSubNavButton).on("click", function() {
				_navigation.toggleSubMenu(this);
			});

			$(_navigation.subnavMobile)
				.find(".subnav-back")
				.on("click", function() {
					_navigation.toggleSubMenu(this);
				});

			$(_navigation.v2Subnav)
				.find(".subnav-back")
				.on("click", function() {
					_navigation.toggleSubMenu(this);
				});

			$(_navigation.toggleSubNavDesktop).on("click", function() {
				_navigation.toggleSubMenuDesktop(this);
			});

			// Main navigation toggle
			$(_navigation.toggleButton).on("click", function() {
				_navigation.toggleMenu();
			});

			$(_navigation.v2ToggleButton).on("click", function() {
				_navigation.v2ToggleMenu();
			});

			$(_navigation.v2CloseIcon).on("click", function() {
				_navigation.v2ToggleMenu();
			});

			$(_navigation.hasIcon).on("click", function(event) {
				_navigation.handleIconClick(event, $(this));
			});

			// $(_navigation.v2SiteSwitch).on("change", function(event) {
			// 	_navigation.switchSites(this);
			// });
		},

    /**
     * Method which toggles the main menu (through a click on the hamburger menu)
     */
		toggleMenu: function() {
			if (this.$bodyEl.hasClass("nav-expanded")) {
				this.closeMenu();
				return;
			}

			this.openMenu();
		},

		v2ToggleMenu: function() {
			if ($(_navigation.v2Header).hasClass("expanded")) {
				this.v2CloseMenu();
				return;
			}

			this.v2OpenMenu();
		},

    /**
     * Method for closing navigation menu
     */
		closeMenu: function() {
			this.$bodyEl.removeClass("init-large nav-expanded");
		},

    /**
     * Method for opening navigation menu
     */
		openMenu: function() {
			this.$bodyEl.addClass("nav-expanded");
		},

    /**
     * Method for closing navigation menu
     */
		v2CloseMenu: function() {
			$(_navigation.v2Header).removeClass("expanded");
		},

    /**
     * Method for opening navigation menu
     */
		v2OpenMenu: function() {
			$(_navigation.v2Header).addClass("expanded");

			$("body").on("click", (event) => {
				if($(_navigation.v2Header).has(event.target).length <= 0) {
					this.v2CloseMenu();
				}
			})
		},

    /**
     * Method which toggles a sub menu of one of the items.
     *
     * @param {HTMLElement} sourceEventElement
     */
		toggleSubMenu: function(sourceEventElement: HTMLElement) {
			var $parent = $(sourceEventElement).parents("li");

			if ($parent.hasClass("expanded")) {
				$parent.removeClass("expanded");
				return;
			}
			$parent.addClass("expanded");
		},

    /**
     * V2 Method which toggles submenu.
     *
     * @param {HTMLElement} sourceEventElement
     */
		toggleSubMenuDesktop: function(sourceEventelement: HTMLElement) {
			var $parent = $(sourceEventelement)
				.parent("div")
				.parent(".nav__item--desktop");

			if ($parent.hasClass("expanded")) {
				$parent.removeClass("expanded");
				$(_navigation.v2Header).removeClass("expanded-desktop");
				return;
			}
			this.closeAllSubMenus();
			$parent.addClass("expanded");
			$(_navigation.v2Header).addClass("expanded-desktop");

			$("body").on("click", (event) => {
			if($(_navigation.v2Header).has(event.target).length <= 0) {
				if($(_navigation.v2Header).hasClass("expanded-desktop")) {
					$(_navigation.v2Header).removeClass("expanded-desktop");
					this.closeAllSubMenus();
				}
			}
		})
		},

		closeAllSubMenus: function() {
			$(document)
				.find(".nav__item--desktop")
				.removeClass("expanded");
		},

    /**
     * Method for opening a sub menu of one of the items
     *
     * @param sourceEventElement
     */
		openSubMenu: function(sourceEventElement: any) {
			var $parent = $(sourceEventElement).parents("li");
			$parent.addClass("expanded");
		},

    /**
     * Method for closing already opened sub menus
     */
		closeOpenSubMenus: function() {
			let expandedSubMenus = $(_navigation.hasIcon + ".expanded");
			expandedSubMenus.removeClass("expanded");
		},

    /**
     * Method for handling click on an item with an icon
     *
     * @param event
     * @param $element
     */
		handleIconClick: function(event: any, $element: any) {
			if (!this.$bodyEl.hasClass("nav-expanded")) {
				event.preventDefault();
				this.openMenu();
				this.closeOpenSubMenus();
				this.openSubMenu($element.find(this.toggleSubNavButton));
			}
		}
	};

	_navigation.created();
	$(function() {
		_navigation.mounted();
	});
}

export class NavigationOptions { }
