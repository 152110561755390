
export interface Text {
    language: string;
    text: string;
}

export interface Header extends Text {

}

export interface Main extends Text {
    tier: number;
}

export interface Tier {
    tier: number;
    minAmount: number;
    unitDiscount: number;
    baseType: string;
    discountType: string;
}

export interface Texts {
    header: Header[];
    main: Main[];
}

export interface ActionInterface {
    actionId: string;
    actionType: string;
    tiers: Tier[];
    texts: Texts;
}

export class Action implements ActionInterface {
    actionId: string = '';
    actionType: string = '';
    tiers: Tier[] = [];
    texts: Texts = {header: [], main: []};

    isFallbackLanguage(language: string): boolean {
        return 'en' === language.toLowerCase();
    }

    isStandardActionType(): boolean {
        return !this.isQuantityDiscountType();
    }

    isQuantityDiscountType(): boolean {
        return 'quantitydiscount' === this.actionType.toLowerCase();
    }

    isPercentageDiscountType(): boolean {
        if (!this.isQuantityDiscountType() && this.tiers && this.tiers.length > 0) {
            const firstTier: Tier = this.tiers[0];
            if (firstTier.discountType) {
                return 'percentage' === firstTier.discountType.toLowerCase();
            }
        }
        return false;
    }

    getHeaderTextByDomain(language: string): Header | undefined {
        if (this.texts) {
            let header: Header | undefined = this.texts.header.find(
                h => h.language.toLowerCase() === language.toLowerCase());
            if (undefined !== header) {
                return header;
            }
            // fallback on 'com'
            if (!this.isFallbackLanguage(language)) {
                return this.getHeaderTextByDomain('en');
            }
        }
        return undefined;
    }

    getMainTextByDomain(language: string): Main[] {
        const mains: Main[] = [];
        if (this.texts) {
                let main: Main | undefined = this.texts.main.find(
                main => main.language.toLowerCase() === language.toLowerCase()
                );
                if (undefined !== main) {
                    mains.push(main);
                }
            // fallback on 'en'
            if (mains.length === 0 && !this.isFallbackLanguage(language)) {
                return this.getMainTextByDomain('en');
            }
        }
        return mains;
    }

    getMainTextByDomainAndTier(language: string, tier: number): Main | undefined {
        if (this.texts) {
            let main: Main | undefined = this.texts.main.find(
                main => main.language.toLowerCase() === language.toLowerCase() && main.tier === tier
            );
            if (undefined !== main) {
                return main;
            }
            // fallback on 'com'
            if (!this.isFallbackLanguage(language)) {
                return this.getMainTextByDomainAndTier('en', tier);
            }
        }
        return undefined;
    }

    getTierByAmount(amount: number): Tier | undefined {
        // find the highest tier such that given amount satisfies the tier's minimum amount
        return Array.from(this.tiers) // be sure to sort on a copy
            .sort((a, b) => b.minAmount - a.minAmount)  // sort from high amount -> low amount
            .find(t => amount >= t.minAmount); // must satisfy min amount
    }

    /**
     * Returns the next (or first) tier for given total aggregated amount op products that are linked to this same
     * action. If such a tier exists, then the user has to order an extra "tier.minAmount - aggregatedAmount" to get
     * that (unit) discount.
     *
     * @param aggregatedAmount
     * @return Tier if available, undefined otherwise.
     */
    getNextTier(aggregatedAmount: number): Tier | undefined {
        // find the lowest tier such that given aggregated amount does not satisfy the tier's minimum amount
        return Array.from(this.tiers) // be sure to sort on a copy
            .sort((a, b) => a.minAmount - b.minAmount)  // sort from low amount -> high amount
            .find(t => aggregatedAmount < t.minAmount); // must NOT satisfy min amount
    }

    /**
     * Creates an instance from given json object.
     *
     * @param json the JSON object to create an instance from.
     */
    static createFromJson(json: ActionInterface): Action {
        return Object.assign(new Action(), json);
    }

    static createArrayFromJson(json: ActionInterface[]): Action[] {
        const array: Action[] = [];
        json.forEach(item => array.push(this.createFromJson(item)));
        return array;
    }
}

