import Vue from "vue";

export function formatLabelKey(key: string): string {
	const formattedKey = key
			.replace(/\//g, "_")
			.replace(/-/g, "_")
			.replace(/\./g, "_")
			.replace(/ /g, "")
			.toLowerCase();
	if (formattedKey.length <= 0) {
		return "";
	}
	return formattedKey;
}

Vue.filter('formatLabelKey', formatLabelKey);
