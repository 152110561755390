
import Vue from "vue";
let loadTriggered = false;

window.addEventListener("load", () => {
  loadTriggered = true;
})

export default Vue.directive("delayed-loading",{
  bind(element: HTMLElement) {
    function initDirective() {
      if(loadTriggered) return;
      element.classList.add("delayed-loading");
    }

    function unmountDirective() {
      element.classList.remove("delayed-loading");
    }

    initDirective();
    window.addEventListener("load", () => unmountDirective());
  }
})
