import { MessageBus } from '@/service/message-bus';

export class ToastMessage {
  private static instance: ToastMessage;

  private constructor(private messageBus: MessageBus) {}

  static getInstance(): ToastMessage {
    if (ToastMessage.instance == null) {
      ToastMessage.instance = new ToastMessage(MessageBus.getInstance());
    }
    return ToastMessage.instance;
  }

  add(message: string, isShoppingCart?: boolean, extraMessage?: string) {
    // todo refactor this properly
    this.messageBus.publish('toast.created', [message, isShoppingCart, extraMessage]);
  }
}
