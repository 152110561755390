import { ProductInfo } from '@/model/product-info';

export interface Delivery {
  date: string;
  quantity: number;
  warehouse: string;
}

export interface WarehouseStock {
  warehouse: string;
  stock: number;
  preferred?: boolean;
  deliveries?: Delivery[];
}

export interface ArticlePrice {
  articleCode: string;
  isPriceSet: boolean;
  itemGrossPrice?: number;
  itemBaseNetPrice?: number;
  itemNetPrice?: number;
  itemQuantityDiscount?: number;
  itemActionDiscountValue?: number;
  itemTotalDiscount?: number;
  itemDiscountPercentage?: number;
}

export interface ArticleLinePrice extends ArticlePrice {
  quantity: number;
  lineTotalAmount?: number;
  lineTotalDiscount?: number;
}

export interface ProductPriceStockInterface extends ArticleLinePrice {
  priceOnRequest?: boolean;
  stocks: WarehouseStock[];
  totalStock: number;
  actions: string[];
  appliedAction?: string;
  quantityDiscounts: string[];
  appliedQuantityDiscount?: string;
  aggregatedQuantities?: any;
  ecotax?: ArticlePrice;
  productInfo?: ProductInfo;
  basketQuantity?: number;
}

export class ProductPriceStock implements ProductPriceStockInterface {
  articleCode: string = '';
  quantity: number = 0;
  isPriceSet: boolean = true;
  priceOnRequest?: boolean;
  itemGrossPrice?: number;
  itemBaseNetPrice?: number;
  itemNetPrice?: number;
  itemQuantityDiscount?: number;
  itemActionDiscountValue?: number;
  itemTotalDiscount?: number;
  itemDiscountPercentage?: number;
  lineTotalAmount?: number;
  lineTotalDiscount?: number;
  productInfo?: ProductInfo;
  stocks: WarehouseStock[] = [];
  totalStockAvailable: number = 0;
  totalStock: number = 0;
  actions: string[] = [];
  appliedAction?: string;
  quantityDiscounts: string[] = [];
  appliedQuantityDiscount?: string;
  aggregatedQuantities?: any;
  ecotax?: ArticlePrice;
  basketQuantity?: number;

  /**
   * Indicates whether this article for its given quantity has a discount.
   */
  hasDiscount(): boolean {
    console.log('item total discount: ' + this.itemTotalDiscount);

    return undefined !== this.itemTotalDiscount && this.itemTotalDiscount > 0;
  }

  /**
   * Returns the aggregated quantity for an action id (which also has the "other" article quantities from the basket
   * incorporated that are linked to the same action). This aggregated quantity can be used to show a Call-to-Action
   * to order an x amount more in order to a discount or a bigger discount (in case of transitioning to the next
   * "tier" of the action).
   *
   * @param actionId id of the action for which to returns the aggregated quantity.
   */
  getAggregatedQuantity(actionId: string | number): number | undefined {
    if (undefined !== this.aggregatedQuantities) {
      return this.aggregatedQuantities[actionId];
    }
    return undefined;
  }

  /**
   * Creates an instance from given json object.
   *
   * @param json the JSON object to create an instance from.
   */
  static createFromJson(json: ProductPriceStockInterface): ProductPriceStock {
    return Object.assign(new ProductPriceStock(), json);
  }
}
