import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/store";
import {Address, Sale, ShoppingCart, ShoppingCartPrice} from "@/model/shopping-cart";
import {Api} from "@/util/api";

/*
 * Namespace config
 */
const namespaced: boolean = true;
const ShoppingCartNamespacePrefix: string = 'ShoppingCartModule/';

/*
 * ShoppingCart State
 */

interface ShoppingCartState {
    shoppingCartPrice: ShoppingCartPrice,
    shoppingCart: ShoppingCart,
    requestInFlight: number,
    submittingOrder: Boolean
}

const state: ShoppingCartState = {
    shoppingCartPrice: <ShoppingCartPrice>{},
    shoppingCart: <ShoppingCart>{},
    requestInFlight: 0,
    submittingOrder: false
};

/*
 * ShoppingCart mutations
 */
enum ShoppingCartMutations {
    setShoppingCart = 'SET_SHOPPING_CART',
    setShoppingCartPrice = 'SET_SHOPPING_CART_PRICE',
    startRequest = 'START_REQUEST',
    finishRequest = 'FINISH_REQUEST',
    startOrderSubmit = 'START_ORDER_SUBMIT',
    finishOrderSubmit = 'FINISH_ORDER_SUBMIT'
}

export const mutations: MutationTree<ShoppingCartState> = {
    [ShoppingCartMutations.setShoppingCart](state, shoppingCart: ShoppingCart) {
        state.shoppingCart = Object.assign({}, {}, shoppingCart);
    },

    [ShoppingCartMutations.setShoppingCartPrice](state, shoppingCartPrice: ShoppingCartPrice) {
        state.shoppingCartPrice = Object.assign({}, {}, shoppingCartPrice);
    },

    [ShoppingCartMutations.startRequest](state, incrementBy: number = 1) {
        state.requestInFlight +=incrementBy;
    },

    [ShoppingCartMutations.finishRequest](state, decreaseBy: number = 1) {
        state.requestInFlight -=decreaseBy;
    },

    [ShoppingCartMutations.finishRequest](state, decreaseBy: number = 1) {
        state.requestInFlight -=decreaseBy;
    },

    [ShoppingCartMutations.startOrderSubmit](state) {
        state.submittingOrder = true;
    },

    [ShoppingCartMutations.finishOrderSubmit](state) {
        state.submittingOrder = false;
    }
};


/*
 * ShoppingCart Actions
 */
enum Actions {
    getShoppingCart = 'getShoppingCart',
    getShoppingCartPrice = 'getShoppingCartPrice',
    getShoppingCartAndShoppingCartActive = 'getShoppingCartAndShoppingCartActive',
    changeProductOrderAmount = 'changeProductOrderAmount',
    removeProduct = 'removeProduct',
    changeDeliveryAddress = 'changeDeliveryAddress',
    changeCountryToBeDeliveryTo = 'changeCountryToBeDeliveryTo',
    changeExtraInformation = 'changeExtraInformation',
    changeDeliveryOption = 'changeDeliveryOption',
    changeOrderedBy = 'changeOrderedBy',
    submitOrder = 'submitOrder'
}

const actions: ActionTree<ShoppingCartState, RootState> = {
    [Actions.getShoppingCart]({ commit }: any): Promise<ShoppingCart> {
        commit(ShoppingCartMutations.startRequest);
        return Api.getInstance()
            .getShoppingCart()
            .then((shoppingCart: ShoppingCart) => {
                commit(ShoppingCartMutations.setShoppingCart, shoppingCart);
                return Promise.resolve(shoppingCart);
            })
            .finally(() => {
                commit(ShoppingCartMutations.finishRequest);
            });
    },

    [Actions.getShoppingCartPrice]({commit}: any): Promise<ShoppingCartPrice> {
        commit(ShoppingCartMutations.startRequest);
        return Api.getInstance()
            .getActiveShoppingCartPrice()
            .then((shoppingCartPrice: ShoppingCartPrice) => {
                commit(ShoppingCartMutations.setShoppingCartPrice, shoppingCartPrice);
                return Promise.resolve(shoppingCartPrice);
            })
            .finally(() => {
                commit(ShoppingCartMutations.finishRequest);
            });
    },

    [Actions.getShoppingCartAndShoppingCartActive]({ commit }: any)
        : Promise<{ shoppingCart: ShoppingCart, shoppingCartPrice: ShoppingCartPrice }> {

        commit(ShoppingCartMutations.startRequest, 2);

        return Promise.all([
            Api.getInstance().getShoppingCart(),
            Api.getInstance().getActiveShoppingCartPrice(),
            ]).then(res => {
                const shoppingCart: ShoppingCart = res[0];
                const shoppingCartPrice: ShoppingCartPrice = res[1];

                commit(ShoppingCartMutations.setShoppingCart, shoppingCart);
                commit(ShoppingCartMutations.setShoppingCartPrice, shoppingCartPrice);

                return Promise.resolve({ shoppingCart, shoppingCartPrice } )
            })
            .finally(() => {
                commit(ShoppingCartMutations.finishRequest, 2);
            });
    },

    [Actions.changeProductOrderAmount]({commit, dispatch}, product: { productKey: string, amount: number }): Promise<ShoppingCart> {
        commit(ShoppingCartMutations.startRequest);
        return Api.getInstance()
            .putProductInShoppingCart(product.productKey, product.amount)
            .then((shoppingCart: ShoppingCart) => {
                commit(ShoppingCartMutations.setShoppingCart, shoppingCart);
                return dispatch(ShoppingCartActions.getShoppingCartPrice, null, { root: true })
                    .then(() => Promise.resolve(shoppingCart));
            })
            .finally(() => {
                commit(ShoppingCartMutations.finishRequest);
            });
    },

    [Actions.removeProduct]({commit, dispatch}, productKey: string): Promise<ShoppingCart> {
        commit(ShoppingCartMutations.startRequest);
        return Api.getInstance()
            .removeProductFromShoppingCart(productKey)
            .then((shoppingCart: ShoppingCart) => {
                commit(ShoppingCartMutations.setShoppingCart, shoppingCart);
                dispatch(ShoppingCartActions.getShoppingCartPrice, null, { root: true });
                return Promise.resolve(shoppingCart)
            })
            .finally(() => {
                commit(ShoppingCartMutations.finishRequest);
            });
    },

    [Actions.changeDeliveryAddress]({ commit }, address: Address): Promise<ShoppingCart> {
        commit(ShoppingCartMutations.startRequest);
        return Api.getInstance()
            .updateShoppingCartDeliveryAddress(address)
            .then((shoppingCart: ShoppingCart) => {
                commit(ShoppingCartMutations.setShoppingCart, shoppingCart);
                return Promise.resolve(shoppingCart);
            })
            .finally(() => {
                commit(ShoppingCartMutations.finishRequest);
            });
    },

    [Actions.changeCountryToBeDeliveryTo]({ commit, dispatch }, address: Address): Promise<ShoppingCart> {
        return dispatch(ShoppingCartActions.changeDeliveryAddress, address, { root: true })
            .then((shoppingCart: ShoppingCart) => {
                return dispatch(ShoppingCartActions.getShoppingCartPrice, null, { root: true })
                    .then(() => Promise.resolve(shoppingCart))
            })
    },

    [Actions.changeExtraInformation]({ commit, dispatch }, extraInformation: {customerReference: string, remark: string, deliveryContactName: string, deliveryPhone: string})
        : Promise<ShoppingCart> {

        commit(ShoppingCartMutations.startRequest);
        return Api.getInstance()
            .updateShoppingCartExtraInformation(extraInformation)
            .then((shoppingCart: ShoppingCart) => {
                commit(ShoppingCartMutations.setShoppingCart, shoppingCart);
                return Promise.resolve(shoppingCart)
            }).finally(
                () => {commit(ShoppingCartMutations.finishRequest);}
            );
    },

    [Actions.changeDeliveryOption]({ commit, dispatch },
                                   changedDeliveryOption: { optionId: string, optionValue: boolean })
        : Promise<ShoppingCart> {

        commit(ShoppingCartMutations.startRequest);
        return Api.getInstance()
            .updateDeliveryOption(changedDeliveryOption.optionId, changedDeliveryOption.optionValue)
            .then((shoppingCart: ShoppingCart) => {
                commit(ShoppingCartMutations.setShoppingCart, shoppingCart);
                return dispatch(ShoppingCartActions.getShoppingCartPrice, null, { root: true })
                    .then(() => Promise.resolve(shoppingCart))
            })
            .finally(() => {
                commit(ShoppingCartMutations.finishRequest);
            });
    },

    [Actions.submitOrder]({ commit, dispatch }): Promise<Sale> {
        commit(ShoppingCartMutations.startOrderSubmit);
        return Api.getInstance()
            .finalizeOrder()
            .then((sale: Sale) => {
                commit(ShoppingCartMutations.finishOrderSubmit);
                return Promise.resolve(sale);
            })
    }


};

export const ShoppingCartActions = Object.freeze({
    getShoppingCart: ShoppingCartNamespacePrefix + Actions.getShoppingCart,
    getShoppingCartPrice: ShoppingCartNamespacePrefix + Actions.getShoppingCartPrice,
    getShoppingCartAndShoppingCartActive: ShoppingCartNamespacePrefix + Actions.getShoppingCartAndShoppingCartActive,
    changeProductOrderAmount: ShoppingCartNamespacePrefix + Actions.changeProductOrderAmount,
    removeProduct: ShoppingCartNamespacePrefix + Actions.removeProduct,
    changeDeliveryAddress: ShoppingCartNamespacePrefix + Actions.changeDeliveryAddress,
    changeCountryToBeDeliveryTo: ShoppingCartNamespacePrefix + Actions.changeCountryToBeDeliveryTo,
    changeExtraInformation: ShoppingCartNamespacePrefix + Actions.changeExtraInformation,
    changeDeliveryOption: ShoppingCartNamespacePrefix + Actions.changeDeliveryOption,
    changeOrderedBy: ShoppingCartNamespacePrefix + Actions.changeOrderedBy,
    submitOrder: ShoppingCartNamespacePrefix + Actions.submitOrder,
});

/*
 * Getters
 */
enum Getters {
    isLoading = 'isLoading',
    hasProductWithPriceOnRequest = 'hasProductWithPriceOnRequest',
}

const getters: GetterTree<ShoppingCartState, RootState> = {
    [Getters.isLoading]: (state): boolean => {
        return state.requestInFlight > 0
    },

    [Getters.hasProductWithPriceOnRequest]: (state): boolean => {
        if(state.shoppingCartPrice.products === undefined) return false;

        const productWithPriceOnRequest = Object.keys(state.shoppingCartPrice.products).find((key) => {
            return state.shoppingCartPrice.products[key].priceOnRequest === true;
        });

        return productWithPriceOnRequest !== undefined;
    },
};

export const ShoppingCartGetters = Object.freeze({
    isLoading: ShoppingCartNamespacePrefix + Getters.isLoading,
    hasProductWithPriceOnRequest: ShoppingCartNamespacePrefix + Getters.hasProductWithPriceOnRequest,
});

/*
 * Module export
 */
export const ShoppingCartModule: Module<ShoppingCartState, RootState> = {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
