import Vue from 'vue';
import Vuex, {StoreOptions, Module} from "vuex";
import {ShoppingCartModule} from "@/store/modules/shopping-cart";
import {ExchangeRatesModule, ExchangeRatesState} from "@/store/modules/exchange-rates";

Vue.use(Vuex);

export interface RootState {
    ExchangeRatesModule?: Module<ExchangeRatesState, RootState>
}

const store: StoreOptions<RootState> = {
    state: {},
    mutations: {},
    actions: {},
    modules: {
        ShoppingCartModule,
        ExchangeRatesModule
    }
};

export default new Vuex.Store(store);
