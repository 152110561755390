import { BrandDiscount, BrandDiscounts } from "@/model/brand-discount";
import { Config } from "@/model/config";
import { Product } from "@/model/product";
import { SiteDomainConfiguration } from "@/model/site-domain";
import { Translations } from "@/model/translations";
import { ExchangeRates } from "@/model/exchange-rates";
import { WarrantyForm } from "@/model/forms";
import axios from "axios";
import { translate } from "@/filters";
import { ToastMessage } from "@/service/toast-message";

export class Util {
  static getUUID(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static getSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams(window.location.search);
    return Util.getCleanedSearchParams(searchParams);
  }

  static getCleanedSearchParams(searchParams: URLSearchParams) {
    const cleanedSearchParams = new URLSearchParams(searchParams);
    const allowedParams = ["p", "rpp", "q", ...Util.getAvailableProductSpecs()];

    searchParams.forEach((value, key) => {
      if (!allowedParams.includes(key)) {
        cleanedSearchParams.delete(key);
      }
    });
    return cleanedSearchParams;
  }

  static getAvailableProductSpecs(): string[] {
    const availableProductSpecs = (<any>window)["_availableProductSpecs"] || [];
    return availableProductSpecs.reduce((acc: string[], cur: string) => {
      acc.push(cur, cur + "-from", cur + "-to");
      return acc;
    }, []);
  }

  static getConfig(): Config {
    return (<any>window)["_mgnl"];
  }

  static getTranslations(): Translations {
    return (<any>window)["_translations"];
  }

  static isLoggedIn(): boolean {
    return Util.getConfig().customerCountry != null;
  }

  static getBrandDiscounts(product: Product): BrandDiscounts {
    // Ugly, needs to be removed/generalized
    // Special brandDiscounts for Alliance, Agricultural, profile 372 AGRIFLEX or 372 AGRIFLEX+, rimInch = 26 - 42
    // See HEUV-854
    if (product) {
      if (product.bu == "Truck") {
        return (<any>window)["brandDiscounts"];
      } else if (
        product.bu == "Agricultural" &&
        product.brand == "Alliance" &&
        (product.profile == "372 AGRIFLEX" || product.profile == "372 AGRIFLEX+")
      ) {
        // Filter by rimInch HEUV-854 :(
        const rimInch = product.rimInch;
        const profile = product.profile;
        const list: Array<BrandDiscount> = (<any>window)["brandDiscounts"].Alliance;
        if (list == null) return {};
        return { Alliance: list.filter(bd => bd.properties.rimInch == rimInch && bd.properties.profile == profile) };
      }
    }
    return {};
  }

  static areBusinessUnitsEqual(businessUnitA: string, businessUnitB: string) {
    return businessUnitA.toLowerCase() === businessUnitB.toLowerCase();
  }

  static areBrandsEqual(brandA: string, brandB: string) {
    return brandA.toLowerCase() === brandB.toLowerCase();
  }

  static areCategoriesEqual(categoryA: string, categoryB: string) {
    return categoryA.toLowerCase() === categoryB.toLowerCase();
  }

  static getGTMDataLayer(): any {
    return (<any>window)["dataLayer"];
  }

  static getSiteDomainConfiguration(): SiteDomainConfiguration {
    return (<any>window)["_siteDomainConfiguration"];
  }

  static getFilterableFilters(): Array<string> {
    return (<any>window)["_filterableFilters"] || [];
  }

  static getProductBusinessUnit(): string {
    return (<any>window)["_productBusinessUnit"] || "";
  }

  static getUetq(): [{ gv: number }] {
    return (<any>window)["uetq"] || [];
  }

  static getProductCategory(): string {
    return (<any>window)._productCategory || "";
  }

  static getExchangeRate(): ExchangeRates {
    const rate = Util.getConfig().exchangeRate;
    if (rate) {
      return rate;
    }
    return {
      currency: "EURO",
      lastModified: new Date().toISOString(),
      exchangeRate: 1.0,
      htmlDisplay: "€"
    };
  }

  static async createFileFromUrl(url: string, filename: string) {
    try {
      // Step 1: Fetch the file at the URL
      const response = await axios.get(url);

      // Step 2: Get the Blob from the response
      const blob = await response.data.blob();

      // Step 3: Create a File object
      const file = new File([blob], filename, { type: blob.type });

      return file;
    } catch (error) {
      console.error("Error creating file from URL:", error);
    }
  }

  static isMobile(): boolean {
    return window.matchMedia(
      "only screen and (max-width: 480px) and (orientation: portrait), only screen and (max-height: 480px) and (orientation: landscape)"
    ).matches;
  }

  static isTablet(): boolean {
    return window.matchMedia("only screen and (min-width: 992px) and (max-width: 1200px)").matches;
  }

  static isDesktop(): boolean {
    return window.matchMedia("only screen and (min-width: 1201px)").matches;
  }

  static isHomePage(): boolean {
    return document.body.classList.contains("page-home");
  }

  static randomString(): string {
    return Math.random()
      .toString(36)
      .slice(2);
  }

  static resourcePath(): string {
    return this.getConfig().resourcePath;
  }

  static isValidWarrantyFormKey(key: string): key is keyof WarrantyForm {
    return /^photoUrl[1-9]$/.test(key) || /^upload[1-9]$/.test(key);
  }

  static hasBackorder(orderAmount: number, totalStock: number, totalStockAvailable: number): boolean {
    return totalStock > 0 && orderAmount > totalStockAvailable;
  }

  static shoppingCartToast(amount: number, totalStock: number, totalStockAvailable: number, product: Product | null) {
    const toastMessage = amount + "X " + translate("toastMessage.product.added", product && product.getLocalizedDescription());
    const extraMessage = this.hasBackorder(amount, totalStock, totalStockAvailable) ? translate("toastMessage.product.hasBackorder") : "";
    ToastMessage.getInstance().add(toastMessage, true, extraMessage);
  }

  static customerCountry(): string {
    return Util.getConfig().customerCountry;
  }

  static showEcoTaxMessage(businessUnit: string, productCategory: string, itemCondition: string): boolean {
    const customerCountry = this.customerCountry().toUpperCase();
    const isCountryFrance = customerCountry === 'FR';

    return isCountryFrance && (productCategory === "Banden") && (itemCondition !== "Gebruikt");
  }
}
