import _Vue from "vue";

export function CategorySelector(Vue: typeof _Vue, options?: CategorySelectorOptions): void {
	let overviewId = "main-articles-overview";
	let containerClass = "select-container";

	if(options) {
		overviewId = options.overviewId || overviewId;
		containerClass = options.containerClass || containerClass;
	}

	window.addEventListener('load', function () {
		const overview = document.getElementById(overviewId);
		if(overview !== null) {
			const selectContainers = overview.getElementsByClassName(containerClass);
			for (let i = 0; i < selectContainers.length; i++) {
				selectContainers[i].addEventListener('click', function (this: HTMLElement) {
					if(this.classList.contains("expanded")) {
						this.classList.remove("expanded")
					} else {
						this.classList.add("expanded");
					}
				})
			}
		}
	})
}

export class CategorySelectorOptions {
	overviewId: string | undefined;
	containerClass: string | undefined;
}
