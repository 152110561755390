import _Vue from "vue";

//TODO: Rewrite jQuery
export function FiltersSm(Vue: typeof _Vue, options?: FiltersSmOptions): void {
	$(function() {
		$('.meta-navigation .filtering, .filters .icon-close').on('click', function() {
			$('body').toggleClass('show-filters');
		});
	});
}

export class FiltersSmOptions {
}
