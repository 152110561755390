import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/store";
import { Api } from "@/util/api";
import { ExchangeRates } from "@/model/exchange-rates";

/*
 * Namespace config
 */
const namespaced: boolean = true;
const ExchangeRatesNamespacePrefix: string = 'ExchangeRatesModule/';

/*
 * ExchangeRates State
 */

export interface ExchangeRatesState {
  exchangeRates: ExchangeRates[],
  requestInFlight: number,
}

const state: ExchangeRatesState = {
  exchangeRates: [],
  requestInFlight: 0
};

/*
 * ExchangeRates mutations
 */
enum ExchangeRatesMutations {
  setExchangeRates = 'SET_EXCHANGE_RATES',
  startRequest = 'START_REQUEST',
  finishRequest = 'FINISH_REQUEST',
}

export const mutations: MutationTree<ExchangeRatesState> = {
  [ExchangeRatesMutations.setExchangeRates](state, exchangeRates: ExchangeRates[]) {
    state.exchangeRates = [...exchangeRates]
  },

  [ExchangeRatesMutations.startRequest](state, incrementBy: number = 1) {
    state.requestInFlight += incrementBy;
  },

  [ExchangeRatesMutations.finishRequest](state, decreaseBy: number = 1) {
    state.requestInFlight -= decreaseBy;
  },
};


/*
 * ExchangeRates Actions
 */
enum Actions {
  fetchExchangeRates = 'fetchExchangeRates'
}

const actions: ActionTree<ExchangeRatesState, RootState> = {
  [Actions.fetchExchangeRates]({commit}: any): Promise<ExchangeRates[]> {
    commit(ExchangeRatesMutations.startRequest);

    return Api.getInstance().getCurrencyExchangeRates().then((response: ExchangeRates[]) => {
      commit(ExchangeRatesMutations.setExchangeRates, response);
      return Promise.resolve(response);
    }).finally(() => {
      commit(ExchangeRatesMutations.finishRequest);
    });
  }
};

export const ExchangeRatesActions = Object.freeze({
  fetchExchangeRates: ExchangeRatesNamespacePrefix + Actions.fetchExchangeRates
});

/*
 * Getters
 */
enum Getters {
  getExchangeRates = 'getExchangeRates'
}

const getters: GetterTree<ExchangeRatesState, RootState> = {
  [Getters.getExchangeRates]: (state): ExchangeRates[] => {
    return state.exchangeRates;
  }
};

export const ExchangeRatesGetters = Object.freeze({
  getExchangeRates: ExchangeRatesNamespacePrefix + Getters.getExchangeRates
});

/*
 * Module export
 */
export const ExchangeRatesModule: Module<ExchangeRatesState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
