import _Vue from "vue";

//TODO: Rewrite jQuery
export function HideOrderButton(Vue: typeof _Vue, options?: HideOrderButtonOptions): void {
	$(function() {
		const isBelowView = function(el: any) {
			let elementTop = el.offset().top;
			let elementBottom = elementTop + el.outerHeight();

			let viewportTop = $(window).scrollTop();
			// @ts-ignore
			let viewportBottom = viewportTop + $(window).height();

			return elementBottom < viewportBottom;
		};

		if (document.getElementById('order-now')) {
			$(window).on('resize scroll', function() {
				const floatingOrderButton = $('#order-now');

				$('.add-to-cart').each(function() {
					if (isBelowView($(this))) {
						floatingOrderButton.addClass('collapse');
					} else {
						floatingOrderButton.removeClass('collapse');
					}
				});
			});
		}

	});

}

export class HideOrderButtonOptions {
}
