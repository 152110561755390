import Vue from "vue";

export function formatPrice(price: string | number, exchange: boolean = false, digits: number = 2): string {

  if (typeof price === 'string') {
    price = parseFloat(price);
  }

  if (!price) {
    if (price === 0) {
      return `0,00`;
    }
    return "";
  }

  const priceAsString = price.toFixed(digits);
  const splits = priceAsString.toString().split('.');
  const integers = splits[0];
  const decimals = splits[1];
  return `${integers},${decimals}`;
}

Vue.filter('formatPrice', formatPrice);
