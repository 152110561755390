import { formatLabelKey } from "@/filters/format-label-key";
import { translate } from "@/filters/translate";

export function translateProductSpec(filterName: string) {
	let suffix = null;
	let translationKey = `product.specs.${filterName}`;

	if (filterName.includes('-from')) {
		suffix = translate(`serp.filter.range.placeholderFrom`)
		translationKey = translationKey.split('-from')[0];
	}
	if (filterName.includes('-to')) {
		suffix = translate(`serp.filter.range.placeholderTo`)
		translationKey = translationKey.split('-to')[0];
	}

	const translation = translate(translationKey);

	if (translation === translationKey) {
		return filterName;
	}

	if (suffix) {
		return `${translation} ${suffix.toLocaleLowerCase()}`;
	} else {
		return translation;
	}
}

export function translateProductFilterOption(filterName: string, filterOptionLabel: string) {
	if(filterOptionLabel === undefined) return "";
	const translatableOptionLabel = formatLabelKey(filterOptionLabel);
	const translationKey = `product.specs.${filterName}.options.${translatableOptionLabel}`;
	const translation = translate(translationKey);

	if (translation === translationKey) {
		return filterOptionLabel;
	}
	return translation;
}
