import Vue from "vue";

export const formatDate = (dateString: string, format: string = "dd-mm-yyyy", separator: string = "-") => {
	if(dateString === null) {
		return "";
	}

	const date = new Date(dateString)
	let splittedFormat = format.split(separator)

	if (!splittedFormat.includes('dd') || !splittedFormat.includes('mm') || !splittedFormat.includes('yyyy')) {
		console.warn("Date format not supported. Fallback to dd-mm-yyyy.")
		format = 'dd-mm-yyyy'
		separator = '-'
	}
	splittedFormat = format.split(separator);

	let dd = (0 + date.getDate().toString()).slice(-2);
	const mm = (0 + (date.getMonth() + 1).toString()).slice(-2);
	const yyyy = date.getFullYear();

	const newDate = [];
	newDate[splittedFormat.indexOf('dd')] = dd;
	newDate[splittedFormat.indexOf('mm')] = mm;
	newDate[splittedFormat.indexOf('yyyy')] = yyyy;

	return newDate.join(separator);
};

Vue.filter('formatDate', formatDate);
