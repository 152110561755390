export class FoundProductsOption {
	docs: number;
	label: string;
	remainingCount: number;
	value: string;
	selected: boolean;
	disabled: boolean;

	constructor(serverResponse: any) {
		this.docs = serverResponse.docs || 0;
		this.label = serverResponse.label || "";
		this.remainingCount = serverResponse.remainingCount || 0;
		this.value = serverResponse.value || "";
		this.selected = serverResponse.selected || false;
		this.disabled = serverResponse.disabled || false;
	}

	toJS(): {[key in keyof FoundProductsOption]?: any} {
		return {
			docs: this.docs,
			label: this.label,
			remainingCount: this.remainingCount,
			value: this.value,
			selected: this.selected,
			disabled: this.disabled,
		}
	}
}

export class FoundProductsFilter {
	enabled: boolean;
	name: string;
	options: FoundProductsOption[];
	type: string;
	usable: boolean;

	constructor(serverResponse: any) {
		if (serverResponse.name == null) {
			console.error("No name for filter", serverResponse);
			throw new Error("No name for filter");
		}

		if (serverResponse.type == null) {
			console.error("No type for filter", serverResponse);
			throw new Error("No type for filter");
		}

		this.enabled = serverResponse.enabled || false;
		this.name = serverResponse.name;
		this.type = serverResponse.type;
		this.usable = serverResponse.usable || false;
		this.options = this.convertServerResponseToOptions(serverResponse);
	}

	toJS(): {[key in keyof FoundProductsFilter]?: any} {
		return {
			enabled: this.enabled,
			name: this.name,
			options: this.options.map(option => option.toJS()),
			type: this.type,
			usable: this.usable
		}
	}

	private convertServerResponseToOptions(serverResponse: any): FoundProductsOption[] {
		if(serverResponse.options == null) return [];
		return serverResponse.options.map((option: any) => new FoundProductsOption(option))
			.sort((optionA: FoundProductsOption, optionB: FoundProductsOption) => {
				if (optionA.selected && optionB.selected) return 0;
				if (!optionA.selected && optionB.selected) return 1;
				if (optionA.selected && !optionB.selected) return -1;

				if (optionA.disabled && optionB.disabled) return 0;
				if (!optionA.disabled && optionB.disabled) return -1;
				if (optionA.disabled && !optionB.disabled) return 1;


				if (optionA.remainingCount > 0 && optionB.remainingCount > 0) return 0;
				if (optionA.remainingCount > 0 && optionB.remainingCount <= 0) return -1;
				if (optionA.remainingCount <= 0 && optionB.remainingCount > 0) return 1;
				return 0;
			})
	}
}

export type FoundProductsFilterCollection = { [filterName: string]: FoundProductsFilter };

export class FoundProductsVariant {
	id: string;
	bu: string;
	category: string;
	i18nCategory: string;
	columns: string[];
	products: string[];
	totalCount: number;
	filters: FoundProductsFilterCollection;

	constructor(serverResponse: any) {
		this.id = serverResponse.key;
		this.bu = serverResponse.bu.toLowerCase();
		this.category = serverResponse.category;
		this.i18nCategory = serverResponse.i18nCategory;
		this.columns = serverResponse.columns;
		this.products = this.convertServerResponseToProducts(serverResponse);
		this.totalCount = serverResponse.total;
		this.filters = this.convertServerResponseToFilters(serverResponse);
	}

	private convertServerResponseToProducts(serverResponse: any): string[] {
		return serverResponse.products.map((product: { key: string }) => product.key) || [];
	}

	private convertServerResponseToFilters(serverResponse: any): FoundProductsFilterCollection {
		return Object.keys(serverResponse.filters).reduce((aggregation: FoundProductsFilterCollection,
			filterName: string
		) => {
			const filter = new FoundProductsFilter(serverResponse.filters[filterName]);
			aggregation[filter.name] = filter;
			return aggregation;
		}, {}) || {};
	}
}

export class FoundProducts {
	variants: { [id: string]: FoundProductsVariant };
	private readonly cachedUnifiedFilters: FoundProductsFilterCollection;
	private readonly cachedTotalCount: number;
	private readonly cachedProducts: string[];

	constructor(serverResponse: any) {
		this.variants = this.convertServerResponseToVariants(serverResponse);
		this.cachedUnifiedFilters = this.unifyFiltersForVariants();
		this.cachedProducts = this.unifyProductsForVariants();
		this.cachedTotalCount = this.unifyTotalCountForVariants();
	}

	get totalCount(): number {
		return this.cachedTotalCount;
	}

	get filters(): FoundProductsFilterCollection {
		return this.cachedUnifiedFilters;
	}

	get products(): string[] {
		return this.cachedProducts;
	}

	private convertServerResponseToVariants(serverResponse: any): { [variantId: string]: FoundProductsVariant } {
		const convertedBusinessUnits = serverResponse.reduce((aggregation: { [variantId: string]: FoundProductsVariant },
			data: any
		) => {
			const variant = new FoundProductsVariant(data);
			aggregation[variant.id] = variant;
			return aggregation;
		}, {});

		return Object.keys(convertedBusinessUnits).sort((buA, buB) => {
			const totalCountA = convertedBusinessUnits[buA].totalCount;
			const totalCountB = convertedBusinessUnits[buB].totalCount;

			if (totalCountA === 0 && totalCountB === 0) return 0;
			if (totalCountA === 0 && totalCountB > 0) return 1;
			if (totalCountA > 0 && totalCountB === 0) return -1;
			return 0;
		}).reduce((aggregation: { [businessUnit: string]: FoundProductsVariant }, currentBu: string) => {
			aggregation[currentBu] = convertedBusinessUnits[currentBu];
			return aggregation;
		}, {});
	}

	private unifyTotalCountForVariants(): number {
		return Object.keys(this.variants).map(
			key => this.variants[key].totalCount)
			.reduce((aggregation, count) => {
				aggregation = aggregation + count;
				return aggregation;
			}, 0);
	}

	private unifyProductsForVariants(): string[] {
		return Object.keys(this.variants).map(
			key => this.variants[key].products)
			.reduce((aggregation, products) => aggregation.concat(products), []);
	}

	private unifyFiltersForVariants(): FoundProductsFilterCollection {
		const businessUnitKeys = Object.keys(this.variants);

		// Honestly would hope to refactor this someday...  but it is what it is
		// The main reason for it is, is that right now we are mutating the source objects and arrays.
		// I would prefer to create a derivative without touching the source.
		return businessUnitKeys.map(name => this.variants[name].filters)
			.reduce((aggregation: FoundProductsFilterCollection,
				businessUnitFilters: FoundProductsFilterCollection
			) => {
				for (let key in businessUnitFilters) {
					// if the filter name doesn't exist we add it to the object
					if (!(key in aggregation)) {
						aggregation[key] = businessUnitFilters[key];
						continue;
					}

					const currentOptionsForFilter = aggregation[key].options;
					const businessUnitOptionsForFilter = businessUnitFilters[key].options;

					// we start merging the options of the filters here to ensure the remaining count is
					// correct and that only 1 option of a specific value exists.
					businessUnitOptionsForFilter
						.forEach(option => {
							const foundOption = currentOptionsForFilter.find(cOption => cOption.value === option.value);

							// if the option doesn't exist we add it here.
							if(foundOption == null) {
								currentOptionsForFilter.push(option);
								return;
							}

							// we recalculate the count of the option here.
							foundOption.remainingCount =  foundOption.remainingCount + option.remainingCount;
						});

					currentOptionsForFilter.sort((a, b) => this.compareOptionLabels(a, b));
				}
				return aggregation;
			}, {});
	}

	private compareOptionLabels(a: FoundProductsOption, b: FoundProductsOption) {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	}

}