import _Vue from "vue";

//TODO: Rewrite jQuery
export function Footer(Vue: typeof _Vue, options?: FooterOptions): void {
	$(function() {
		var _showMoreLinks = {
			showMoreLinks: $('.show-more-links'),

			init: function() {
				_showMoreLinks.showMoreLinks.on('click', function() {
					var $this = $(this);
					var $parent = $this.parents('.link-list-wrapper');

					var more = $this.data('more');
					var less = $this.data('less');

					if ($parent.hasClass('expanded')) {
						$this.html(more);
						$parent.removeClass('expanded');
					} else {
						$this.html(less);
						$parent.addClass('expanded');
					}
				});
			},
		};

		_showMoreLinks.init();
	});

}

export class FooterOptions {
}
