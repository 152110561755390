import {Util} from "@/util/util";
import _Vue from "vue";

//TODO: Rewrite jQuery
export function Maps(Vue: typeof _Vue, options?: MapOptions): void {
	let map, infowindow, q, i;
	let markers = [];

	function initMap() {
		const zoom = 14;
		// @ts-ignore
		const defaultLocation = new google.maps.LatLng(52.55443, 6.64548);
		// @ts-ignore
		map = new google.maps.Map(document.getElementsByClassName('map')[0], {
			center: defaultLocation,
			zoom: zoom,
			disableDefaultUI: true,
			zoomControl: true,
			styles: [
				{
					featureType: 'water',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#d3d3d3',
						},
					],
				},
				{
					featureType: 'transit',
					stylers: [
						{
							color: '#808080',
						},
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.stroke',
					stylers: [
						{
							visibility: 'on',
						},
						{
							color: '#b3b3b3',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'geometry.fill',
					stylers: [
						{
							visibility: 'on',
						},
						{
							color: '#ffffff',
						},
						{
							weight: 1.8,
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#d7d7d7',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'geometry.fill',
					stylers: [
						{
							visibility: 'on',
						},
						{
							color: '#ebebeb',
						},
					],
				},
				{
					featureType: 'administrative',
					elementType: 'geometry',
					stylers: [
						{
							color: '#a7a7a7',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'geometry.fill',
					stylers: [
						{
							visibility: 'on',
						},
						{
							color: '#efefef',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#696969',
						},
					],
				},
				{
					featureType: 'administrative',
					elementType: 'labels.text.fill',
					stylers: [
						{
							visibility: 'on',
						},
						{
							color: '#737373',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#d6d6d6',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{},
				{
					featureType: 'poi',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#dadada',
						},
					],
				},
			],
		});

		// @ts-ignore
		const anchor = new google.maps.Point(25, 50);
		// @ts-ignore
		const scaledSize = new google.maps.Size(50, 50);
		let icon = {
			url: Util.getConfig().resourcePath + '/dist/img/marker.svg',
			anchor: anchor,
			scaledSize: scaledSize,
		};

		// @ts-ignore
		let marker = new google.maps.Marker({
			position: defaultLocation,
			map: map,
			icon: icon,
		});
	}

	function callback(results: any, status: any) {
		// @ts-ignore
		if (status === google.maps.places.PlacesServiceStatus.OK) {
			for (let i = 0; i < results.length; i++) {
				// @ts-ignore
				createMarker(results[i]);
			}
		}
	}

	function handleLocationError(browserHasGeolocation: any, infowindow: any, pos: any) {
		console.log('The Geolocation service failed');
	}

	function startMaps() {
		const googleMapsApiKey = Util.getConfig().googleMapsApiKey;

		$.getScript('https://maps.google.com/maps/api/js?key=' + googleMapsApiKey + '&libraries=places')
			.done(function (script, textStatus) {
				// Google map script loaded successfully
				initMap();
			})
			.fail(function (jqxhr, settings, ex) {
				// Could not load Google Map script
			});
	}

	const maps = document.getElementsByClassName("map");
	if (maps.length > 0) {
		startMaps();
	}
}

export class MapOptions {
}
