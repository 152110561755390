import Vue from "vue";

export function currencySymbol(currencyIsoCode: string): string {
	switch (currencyIsoCode) {
		case "AUD":
			return "AUD";
		case "BGN":
			return "лв";
		case "CHF":
			return "Fr.";
		case "DKK":
			return "DKK";
		case "EUR":
			return "&euro;";
		case "GBP":
			return "&pound;";
		case "HRK":
			return "kn";
		case "NOK":
			return "NOK";
		case "PLN":
			return "zł.";
		case "RON":
			return "L";
		case "SEK":
			return "SEK";
		case "USD":
			return "&dollar;";
		default:
			return currencyIsoCode;
	}
}

Vue.filter('currencySymbol', currencySymbol);
