import { formatDate, translate } from "@/filters";
import { Config } from "@/model/config";
import {
  Address,
  Sale,
  ShoppingCart,
  ShoppingCartPrice
} from "@/model/shopping-cart";
import { MessageBus } from "@/service/message-bus";
import { ToastMessage } from "@/service/toast-message";
import { Util } from "@/util/util";
import axios, { AxiosError } from "axios";
import { ProductPriceStock } from "@/model/product-price-stock";
import { Action } from "@/model/action";
import { Product } from "@/model/product";
import { Customer } from "@/model/customer";
import { Order } from "@/model/order";
import { Claim } from "@/model/claim";
import {
  ClaimForm,
  ConferenceForm,
  ContactForm,
  RegisterForm,
  WarrantyForm
} from "@/model/forms";
import { Quotation } from "@/model/quotation";
import { ClaimDetail } from "@/model/claim-detail";
import { QuotationDetail } from "@/model/quotation-detail";
import { ExchangeRates } from "@/model/exchange-rates";
import { OrderDetails } from "@/model/order-details";
import { OrderReturn } from "@/model/order-return";

export class Api {
  public static readonly flightRequestModifiedEventName: string =
    "flightRequestModified";
  private static className = "Api";
  private static instance: Api;
  private inFlightRequestCounter = 0;

  constructor(private config: Config, private toastMessage: ToastMessage) {}

  static getInstance(): Api {
    if (Api.instance == null) {
      Api.instance = new Api(Util.getConfig(), ToastMessage.getInstance());
    }
    return Api.instance;
  }

  public isLoading(): boolean {
    return this.inFlightRequestCounter > 0;
  }

  /**
   * Method wich fetches orders for logged in user
   *
   * @returns {Promise}
   */
  getOrders(startDate: Date | null, endDate: Date | null): Promise<Order[]> {
    this.startRequest();
    const params = {} as any;

    if (startDate !== null)
      params["startDate"] = formatDate(startDate.toISOString(), "yyyy-mm-dd");
    if (endDate !== null)
      params["endDate"] = formatDate(endDate.toISOString(), "yyyy-mm-dd");

    return axios
      .get(`${this.config.restBasePath.my}/orders`, {
        params
      })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.my}/orders`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which fetches orders for logged in user
   *
   * @returns {Promise}
   */
  getOrder(orderId: string): Promise<OrderDetails> {
    this.startRequest();
    const params = {} as any;

    return axios
      .get(`${this.config.restBasePath.my}/orders/${orderId}`, {
        params
      })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.my}/orders/${orderId}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which submits order return
   *
   * @returns {Promise}
   */
  async submitOrderReturn(data: OrderReturn, submitUrl: string) {
    this.startRequest();

    return axios
      .post(submitUrl, data)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: submitUrl,
          location: "apiMixin"
        });
        return Promise.reject(error.response.data);
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which download the given orders in a zip file
   *
   * @returns {Promise}
   */
  downloadOrderDocuments(
    ids: string[]
  ): Promise<{ data: Blob; fileName: string }> {
    this.startRequest();
    const params = new URLSearchParams();
    ids.forEach(id => params.append("id", id));

    return axios
      .get(`${this.config.restBasePath.my}/download/documents`, {
        params,
        responseType: "blob"
      })
      .then(response => {
        let fileName = "";
        if (response.headers["content-disposition"]) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            response.headers["content-disposition"]
          );
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "").trim();
          }
        }

        return Promise.resolve({ data: response.data, fileName });
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.my}/orders`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which fetches accounts for logged in user
   *
   * @returns {Promise}
   */
  getUserAccounts(): Promise<Customer[]> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.data}/customers`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/customers`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getActionById(id: string): Promise<Action> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.actions}/actions/${id}`)
      .then(response => Promise.resolve(Action.createFromJson(response.data)))
      .catch(error => {
        //this.showErrorToastMessage("getActionById", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.actions}/actions/${id}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getActionsByIds(ids: string[]): Promise<Action[]> {
    this.startRequest();

    return axios
      .post(`${this.config.restBasePath.actions}/actions`, ids)
      .then(response => {
        return Promise.resolve(Action.createArrayFromJson(response.data));
      })
      .catch(error => {
        //this.showErrorToastMessage("getActionsByIds", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.actions}/actions`,
          location: Api.className,
          data: ids
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getQuantityDiscountById(id: string): Promise<Action> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.actions}/quantityDiscounts/${id}`)
      .then(response => Promise.resolve(Action.createFromJson(response.data)))
      .catch(error => {
        //this.showErrorToastMessage("getActionById", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.actions}/quantityDiscounts/${id}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getQuantityDiscountsByIds(ids: string[]): Promise<Action[]> {
    this.startRequest();

    return axios
      .post(`${this.config.restBasePath.actions}/quantityDiscounts`, ids)
      .then(response => {
        return Promise.resolve(Action.createArrayFromJson(response.data));
      })
      .catch(error => {
        //this.showErrorToastMessage("getActionsByIds", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.actions}/quantityDiscounts`,
          location: Api.className,
          data: ids
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getProductPriceStock(
    articleCode: string,
    quantity: number
  ): Promise<ProductPriceStock> {
    this.startRequest();

    return axios
      .get(
        `${
          this.config.restBasePath.shoppingCart
        }/pricestock/${articleCode}/${quantity}`
      )
      .then(response =>
        Promise.resolve(ProductPriceStock.createFromJson(response.data))
      )
      .catch(error => {
        this.showErrorToastMessage("getPriceForProduct", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${
            this.config.restBasePath.shoppingCart
          }/pricestock/${articleCode}/${quantity}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which retrieves a single content node from the search API endpoint.
   */
  getContentNode(id: string): Promise<any> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.content}?_id=${id}`)
      .then(response => Promise.resolve(response.data))
      .then(data => {
        if (data && data.hits && data.hits.hits && data.hits.hits.length > 0) {
          return Promise.resolve(data.hits.hits[0]);
        }
        return Promise.reject(data);
      })
      .catch(error => {
        this.showErrorToastMessage("getContentNode", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.content}?_id=${id}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which retrieves content nodes based on a set of parameters (filters) from the search API
   * endpoint.
   */
  findContentNodes(searchParams: URLSearchParams): Promise<any> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.content}`, {
        params: searchParams
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => {
        this.showErrorToastMessage("findContentNodes", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.content}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which retrieves a single product from the products API endpoint.
   */
  getProduct(key: string): Promise<Product> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.products}/${key}`)
      .then(response => {
        return Promise.resolve(<Product>response.data);
      })
      .catch(error => {
        this.showErrorToastMessage("getProduct", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.products}/${key}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which retrieves the price and stock information for a single product through the prices API endpoint.
   */
  getPriceForProduct(key: string): Promise<any> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.data}/prices/${key}`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        this.showErrorToastMessage("getPriceForProduct", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/prices/${key}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method with which we can request multiple product prices with a single call.
   */
  getPriceForProducts(keys: string[]): Promise<any> {
    this.startRequest();

    return axios
      .post(`${this.config.restBasePath.data}/prices`, keys)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        this.showErrorToastMessage("getPriceForProducts", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/prices`,
          location: Api.className,
          data: keys
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which retrieves the active shopping cart information excluding price information.
   */
  getShoppingCart(): Promise<ShoppingCart> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.shoppingCart}/active`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        this.showErrorToastMessage("getShoppingCart", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.shoppingCart}/active`,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which puts the specified product (by key) and the newly set amount into the shopping cart.
   */
  putProductInShoppingCart(
    productKey: string,
    amount: number
  ): Promise<ShoppingCart> {
    this.startRequest();

    return axios
      .put(`${this.config.restBasePath.shoppingCart}/active`, {
        articleCode: productKey,
        quantity: amount
      })
      .then(function(response) {
        return Promise.resolve(<ShoppingCart>response.data);
      })
      .catch(error => {
        this.showErrorToastMessage("putProductInShoppingCart", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.shoppingCart}/active`,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which removes the specified product (by key) from the shopping cart.
   * @param {string} productKey
   * @returns {Promise}
   */
  removeProductFromShoppingCart(productKey: string): Promise<ShoppingCart> {
    return this.putProductInShoppingCart(productKey, 0);
  }

  /**
   * Method which retrieves the price information for the content of the currently active shopping cart.
   */
  getActiveShoppingCartPrice(): Promise<ShoppingCartPrice> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.shoppingCart}/active/priceInfo`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        this.showErrorToastMessage("getActiveShoppingCartPrice", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.shoppingCart}/active/priceInfo`,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which retrieves products based on a set of parameters (filters) from the products API endpoint.
   */
  findProducts(searchParams: URLSearchParams): Promise<any[]> {
    this.startRequest();

    return axios
      .get(this.config.restBasePath.products, {
        params: searchParams
      })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        this.showErrorToastMessage("findProducts", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.products}`,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getSuggestedProductKeys(max: number = 3): Promise<any[]> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.data}/suggested-products/${max}`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        this.showErrorToastMessage("getSuggestedProducts", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/suggested-products/${max}`,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method for retrieving alternatives for product with productkey {key}
   * @param {string} key Product key for which to retrieve alternatives
   * @param {number} max Maximum amount of results to retrieve
   * @param {boolean} keys_only Wether this method should return only productKeys, or the product data itself
   * @returns {Promise}
   */
  getAlternativeProductKeys(
    key: string,
    max: number,
    keys_only: boolean = true
  ): Promise<any[]> {
    this.startRequest();

    return axios
      .get(
        `${
          this.config.restBasePath.products
        }/${key}/alternatives?max=${max}&codes=${keys_only}`
      )
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        this.showErrorToastMessage("getAlternativeProducts", error);
        console.error("Server responded with an error", {
          error: error,
          url: `${
            this.config.restBasePath.products
          }/${key}/alternatives?max=${max}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which updates the shopping cart delivery address.
   * @param {{city: string, country: string, street: string, postalcode: string}} address
   * @returns {Promise}
   */
  updateShoppingCartDeliveryAddress(address: Address): Promise<ShoppingCart> {
    this.startRequest();

    return axios
      .put(
        `${this.config.restBasePath.shoppingCart}/active/deliveryAddress`,
        address
      )
      .then(response => {
        return Promise.resolve(<ShoppingCart>response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.shoppingCart}/active`,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which updates the additional information of the shopping cart. Things like order reference, order notes etc.
   *
   * @param {{customerReference: string, remark: string}} extraInformation
   * @returns {Promise}
   */
  updateShoppingCartExtraInformation(extraInformation: {
    customerReference: string;
    remark: string;
    deliveryContactName: string;
    deliveryPhone: string;
  }): Promise<ShoppingCart> {
    this.startRequest();

    return axios
      .put(
        `${this.config.restBasePath.shoppingCart}/active/extraInformation`,
        extraInformation
      )
      .then(response => {
        return Promise.resolve(<ShoppingCart>response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${
            this.config.restBasePath.shoppingCart
          }/active/extraInformation`,
          location: "apiMixin"
        });
        const toastMessage = translate("toastMessage.extraInformation.error");
        MessageBus.getInstance().publish("toast.created", [toastMessage]);
        return Promise.reject("Server responded with an error");
      })
      .finally(() => this.finishRequest());
  }

  /**
   * Method which updates the delivery option. It can either set or unset a selected option, which is determined by
   * the boolean which is passed to this method.
   *
   * @param {string} optionId
   * @param {boolean} optionValue
   * @returns {Promise}
   */
  updateDeliveryOption(
    optionId: string,
    optionValue: boolean
  ): Promise<ShoppingCart> {
    this.startRequest();

    if (optionValue === false) {
      return axios
        .delete(
          `${
            this.config.restBasePath.shoppingCart
          }/active/deliveryOptions/${optionId}`
        )
        .then(response => {
          return Promise.resolve(<ShoppingCart>response.data);
        })
        .catch(error => {
          console.error("Server responded with an error", {
            error: error,
            url: `${
              this.config.restBasePath.shoppingCart
            }/active/deliveryOptions/${optionId}`,
            location: "apiMixin"
          });
          return Promise.reject("Server responded with an error");
        })
        .finally(() => {
          this.finishRequest();
        });
    }

    return axios
      .put(
        `${
          this.config.restBasePath.shoppingCart
        }/active/deliveryOptions/${optionId}`
      )
      .then(response => {
        return Promise.resolve(<ShoppingCart>response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${
            this.config.restBasePath.shoppingCart
          }/active/deliveryOptions/${optionId}`,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which finalizes the order, submitting the current content of the shopping cart (by means of the
   * currently set cookie)
   *
   * @returns {Promise}
   */
  finalizeOrder(): Promise<Sale> {
    this.startRequest();

    return axios
      .post(`${this.config.restBasePath.shoppingCart}/commitOrder`)
      .then(response => {
        return Promise.resolve(<Sale>response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.shoppingCart}/commitOrder`,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  submitProfileEditForm(data: object, submitUrl: string) {
    this.startRequest();

    return axios
      .post(submitUrl, data)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: submitUrl,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which submits the contact form
   *
   * @returns {Promise}
   */
  submitContactForm(data: ContactForm, submitUrl: string) {
    this.startRequest();

    return axios
      .post(submitUrl, data)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: submitUrl,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which submits the contact form
   * NOTE: Copied from Contact Form in case of an extension of business logic
   *
   * @returns {Promise}
   */
  submitConferenceForm(data: ConferenceForm, submitUrl: string) {
    this.startRequest();

    return axios
      .post(submitUrl, data)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: submitUrl,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which submits the registration form
   * Note: Copied from the submitContactForm above for now not knowing if
   * we need to build in special features.
   * We possibly could fix a generic submitForm method that handles both form submissions.
   *
   * @returns {Promise}
   */
  submitRegistrationForm(data: RegisterForm, submitUrl: string) {
    this.startRequest();

    return axios
      .post(submitUrl, data)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: submitUrl,
          location: "apiMixin"
        });
        return Promise.reject(error.response.data);
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which retrieves the active shopping cart information excluding price information.
   * @returns {Promise}
   */
  getActiveShoppingCart() {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.shoppingCart}/active`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.shoppingCart}/active`,
          location: "apiMixin"
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which submits the claim form
   *
   * @returns {Promise}
   */
  async submitClaimForm(data: ClaimForm, submitUrl: string) {
    this.startRequest();

    return axios
      .post(submitUrl, data)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: submitUrl,
          location: "apiMixin"
        });
        return Promise.reject(error.response.data);
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which submits the claim form
   *
   * @returns {Promise}
   */
  async saveWarrantyForm(data: WarrantyForm, submitUrl: string): Promise<any> {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      const warrantyKey = key as keyof WarrantyForm;
      const value = data[warrantyKey];

      if (value instanceof File) {
        // Append file to FormData
        formData.append(warrantyKey, value, value.name);
      } else if (value !== undefined && value !== null) {
        formData.append(warrantyKey, value.toString());
      }
    });

    this.startRequest();

    return axios
      .post(submitUrl, formData)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: submitUrl,
          location: "apiMixin"
        });
        return Promise.reject(error.response.data);
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  async submitWarrantyForm(data: WarrantyForm, submitUrl: string) {
    const formData = new FormData();

    const mountDate = formatDate(
      data.mountDate && data.mountDate,
      "yyyy-mm-dd"
    );
    const demountDate = formatDate(
      data.demountDate && data.demountDate,
      "yyyy-mm-dd"
    );
    const invoiceDate = formatDate(
      data.invoiceDate && data.invoiceDate,
      "yyyy-mm-dd"
    );

    const appendIfNotNullOrUndefined = (
      key: string,
      value: string | null | undefined
    ) => {
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    };

    appendIfNotNullOrUndefined("number", data.number);
    appendIfNotNullOrUndefined("accountNumber", data.accountNumber);
    appendIfNotNullOrUndefined("businessUnit", data.businessUnit);
    appendIfNotNullOrUndefined("productCategory", data.productCategory);

    appendIfNotNullOrUndefined("endUserName", data.endUserName);
    appendIfNotNullOrUndefined("contactPerson", data.contactPerson);
    appendIfNotNullOrUndefined("email", data.email);
    appendIfNotNullOrUndefined("endUserCity", data.endUserCity);
    appendIfNotNullOrUndefined("pickupStreet", data.pickupStreet);
    appendIfNotNullOrUndefined("pickupPostalCode", data.pickupPostalCode);
    appendIfNotNullOrUndefined("pickupCity", data.pickupCity);
    appendIfNotNullOrUndefined("pickupCountry", data.pickupCountry);
    appendIfNotNullOrUndefined("productBrand", data.productBrand);
    appendIfNotNullOrUndefined("productSize", data.productSize);
    appendIfNotNullOrUndefined("productType", data.productType);
    appendIfNotNullOrUndefined("loadSpeedIndex", data.loadSpeedIndex);
    appendIfNotNullOrUndefined("serialNumber", data.serialNumber);
    appendIfNotNullOrUndefined("dot", data.dot);
    appendIfNotNullOrUndefined("remainingTreadDepth", data.remainingTreadDepth);
    appendIfNotNullOrUndefined("tyrePressure", data.tyrePressure);
    appendIfNotNullOrUndefined("description", data.description);
    appendIfNotNullOrUndefined("vehicleBrand", data.vehicleBrand);
    appendIfNotNullOrUndefined("vehicleType", data.vehicleType);
    appendIfNotNullOrUndefined("vehicleRegistration", data.vehicleRegistration);
    appendIfNotNullOrUndefined("usage", data.usage);
    appendIfNotNullOrUndefined("wheelPosition", data.wheelPosition);

    appendIfNotNullOrUndefined("mountDate", data.mountDate && mountDate);
    appendIfNotNullOrUndefined("demountDate", data.demountDate && demountDate);
    appendIfNotNullOrUndefined("tyreMileage", data.tyreMileage);
    appendIfNotNullOrUndefined(
      "invoiceDate",
      data.invoiceNumber && invoiceDate
    );
    appendIfNotNullOrUndefined("invoiceNumber", data.invoiceNumber);
    appendIfNotNullOrUndefined("outOfRound", data.outOfRound as string);
    appendIfNotNullOrUndefined("outOfRoundAmount", data.outOfRoundAmount);
    appendIfNotNullOrUndefined("machineSerialNumber", data.machineSerialNumber);
    appendIfNotNullOrUndefined("machineMountHours", data.machineMountHours);
    appendIfNotNullOrUndefined("machineDemountHours", data.machineDemountHours);
    appendIfNotNullOrUndefined("machineHours", data.machineHours);
    appendIfNotNullOrUndefined("tearSeparation", data.tearSeparation); //

    if (data.upload1) {
      formData.append("upload1", data.upload1);
    } else {
      appendIfNotNullOrUndefined("photoUrl1", data.photoUrl1);
    }

    if (data.upload2) {
      formData.append("upload2", data.upload2);
    } else {
      appendIfNotNullOrUndefined("photoUrl2", data.photoUrl2);
    }

    if (data.upload3) {
      formData.append("upload3", data.upload3);
    } else {
      appendIfNotNullOrUndefined("photoUrl3", data.photoUrl3);
    }

    if (data.upload4) {
      formData.append("upload4", data.upload4);
    } else {
      appendIfNotNullOrUndefined("photoUrl4", data.photoUrl4);
    }

    if (data.upload5) {
      formData.append("upload5", data.upload5);
    } else {
      appendIfNotNullOrUndefined("photoUrl5", data.photoUrl5);
    }

    if (data.upload6) {
      formData.append("upload6", data.upload6);
    } else {
      appendIfNotNullOrUndefined("photoUrl6", data.photoUrl6);
    }

    if (data.upload7) {
      formData.append("upload7", data.upload7);
    } else {
      appendIfNotNullOrUndefined("photoUrl7", data.photoUrl7);
    }

    if (data.upload8) {
      formData.append("upload8", data.upload8);
    } else {
      appendIfNotNullOrUndefined("photoUrl8", data.photoUrl8);
    }

    if (data.upload9) {
      formData.append("upload9", data.upload9);
    } else {
      appendIfNotNullOrUndefined("photoUrl9", data.photoUrl9);
    }

    this.startRequest();

    return axios
      .post(submitUrl, formData)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: submitUrl,
          location: "apiMixin"
        });
        return Promise.reject(error.response.data);
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which fetches orders for logged in user
   *
   * @returns {Promise}
   */
  getClaims(): Promise<Claim[]> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.data}/claims`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/claims`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getClaim(number: String): Promise<ClaimDetail> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.data}/claims/${number}`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/claims/${number}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which fetches warranties  for logged in user
   *
   * @returns {Promise}
   */
  getWarranties(): Promise<WarrantyForm[]> {
    this.startRequest();

    return axios
      .get(`/.rest/v1/warranties/`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/claims`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method which fetches warranty for logged in user
   *
   * @returns {Promise}
   */
  getWarranty(number: string): Promise<WarrantyForm> {
    this.startRequest();

    return axios
      .get(`/.rest/v1/warranties/${number}`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `/.rest/v1/warranties/${number}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  async uploadClaimPhotoStream(photo: File, prefix?: String) {
    this.startRequest();

    const config = {
      headers: {
        "Content-Type": "application/octet-stream"
      }
    };

    const name = encodeURIComponent(prefix ? prefix + photo.name : photo.name);
    const type = encodeURIComponent(photo.type);
    const data = await this.fileToArray(photo);

    return axios
      .post(
        `${
          this.config.restBasePath.claim
        }/upload/stream?name=${name}&type=${type}&size=${photo.size}`,
        data,
        config
      )
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.claim}/upload/stream`,
          location: "apiMixin"
        });
        return Promise.reject(error.response.data);
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  async uploadClaimPhoto(photo: File, prefix?: String) {
    this.startRequest();

    const base64 = await this.fileToBase64(photo);

    return axios
      .post(`${this.config.restBasePath.claim}/upload/image`, {
        fileName: prefix ? prefix + photo.name : photo.name,
        image: base64
      })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.claim}/upload/image`,
          location: "apiMixin"
        });
        return Promise.reject(error.response.data);
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getQuotation(number: String): Promise<QuotationDetail> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.data}/quotations/${number}`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/quotations/${number}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  changeQuotationStatus(
    number: string,
    status: string
  ): Promise<QuotationDetail> {
    this.startRequest();

    return axios
      .post(`${this.config.restBasePath.data}/quotations/${number}`, {
        status: status
      })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/quotations/${number}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  /**
   * Method wich fetches quotations for logged in user
   *
   * @returns {Promise}
   */
  getQuotations(): Promise<Quotation[]> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.data}/quotations`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/quotations`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getCurrencyExchangeRates(): Promise<ExchangeRates[]> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.data}/currency/exchange-rates`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/currency/exchange-rates`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getUserExchangeRates(): Promise<ExchangeRates[]> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.my}/exchange-rates`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.my}/exchange-rates`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  getUserExchangeRate(): Promise<ExchangeRates> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.my}/exchange-rate`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.my}/exchange-rate`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  setUserExchangeRate(currencyCode: string): Promise<ExchangeRates> {
    this.startRequest();

    return axios
      .post(
        `${
          this.config.restBasePath.my
        }/exchange-rate?currencyCode=${currencyCode}`
      )
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${
            this.config.restBasePath.my
          }/exchange-rate?currencyCode=${currencyCode}`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  sendMail(
    productKey: string,
    productDescription: string,
    productEanCode: string,
    amount: number,
    email: string,
    phone: string
  ): Promise<void> {
    this.startRequest();

    return axios
      .post(`${this.config.restBasePath.mail}/productNotification`, {
        productKey,
        productDescription,
        productEanCode,
        amount,
        email,
        phone
      })
      .then(() => {
        return Promise.resolve();
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.mail}/productNotification`,
          location: Api.className
        });
        return Promise.reject("Server responded with an error");
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  isRelevant(productKey: string): Promise<Boolean> {
    this.startRequest();

    return axios
      .get(`${this.config.restBasePath.data}/relevant/${productKey}`)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error("Server responded with an error", {
          error: error,
          url: `${this.config.restBasePath.data}/relevant/${productKey}`,
          location: Api.className
        });
      })
      .finally(() => {
        this.finishRequest();
      });
  }

  private showErrorToastMessage(
    apiTranslationKey: string,
    error: AxiosError
  ): void {
    let statusCode = "unknown";
    if (error.response && error.response.status) {
      statusCode = error.response.status.toString(10);
    }
    this.toastMessage.add(
      translate(`toastMessage.api.error.${apiTranslationKey}`, statusCode)
    );
  }

  private startRequest() {
    this.inFlightRequestCounter++;
    this.flightRequestModified();
  }

  private finishRequest() {
    this.inFlightRequestCounter--;
    this.flightRequestModified();
  }

  private flightRequestModified(): void {
    if (!!Event.prototype && !!Event.prototype.constructor.name) {
      const event = new CustomEvent(Api.flightRequestModifiedEventName);
      document.dispatchEvent(event);
    } else {
      // Fallback to createEvent that IE11 supports
      // note: createEvent is deprecated. https://developer.mozilla.org/en-US/docs/Web/API/Document/createEvent
      const flightRequestModifiedEvent = document.createEvent("HTMLEvents");
      flightRequestModifiedEvent.initEvent(
        Api.flightRequestModifiedEventName,
        true,
        true
      );
      document.dispatchEvent(flightRequestModifiedEvent);
    }
  }

  private async fileToBase64(file: File): Promise<String | ArrayBuffer | null> {
    console.log(
      "fileToBase64: name=" +
        file.name +
        ", type=" +
        file.type +
        ", size=" +
        file.size
    );
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => res(reader.result);
      reader.onerror = error => rej(error);
    });
  }

  private async fileToArray(file: File): Promise<String | ArrayBuffer | null> {
    console.log(
      "fileToArray: name=" +
        file.name +
        ", type=" +
        file.type +
        ", size=" +
        file.size
    );
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => res(reader.result);
      reader.onerror = error => rej(error);
    });
  }
}
