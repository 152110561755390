
import Vue from "vue";

export default Vue.directive("element-rendered", {
	bind(element: HTMLElement, binding: any) {
		const renderedEvent = new CustomEvent("rendered", {
			detail: element,
			bubbles: true,
			cancelable: true
		});

		const checkContent = (retries = 0) => {
			if(element.children.length > 0) {
				element.dispatchEvent(renderedEvent);
				return;
			}

			if(retries > 10) {
				return;
			}

			setTimeout(() => {
				checkContent(retries + 1);
			}, 10);
		};
		checkContent();
	}
});
