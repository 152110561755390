import _Vue from "vue";
import {formatPrice} from "@/filters";

//TODO: Rewrite jQuery
export function StyleGuide(Vue: typeof _Vue, options?: StyleGuideOptions): void {
	$(function() {
		var calculators = $('.incrementor');

		calculators.each(function() {
			var c = $(this);

			c.on('change', function() {
				const total = c.find('.total');
				const totalPriceField = $('.totalPrice-' + total.attr('name'));

				const amount = total.val();
				const productPrice = $('#productPrice').val();
				// @ts-ignore
				const totalPrice = parseFloat(String(productPrice * amount)).toFixed(2);
				totalPriceField.html(formatPrice(totalPrice));
			});
		});
	});

}

export class StyleGuideOptions {
}
