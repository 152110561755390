import { Observable, ReplaySubject } from "rxjs";
import { publishReplay, refCount } from "rxjs/operators";

export class BrowserHistory {
	private static instance: BrowserHistory;
	private historySubject = new ReplaySubject<PopStateEvent>(1);

	private constructor() {
		window.onpopstate = (state: PopStateEvent) => {
			this.historySubject.next(state);
		};
	}

	static getInstance(): BrowserHistory {
		if (BrowserHistory.instance == null) {
			BrowserHistory.instance = new BrowserHistory();
		}
		return BrowserHistory.instance;
	}

	historyChanged(): Observable<PopStateEvent> {
		return this.historySubject.asObservable().pipe(publishReplay(), refCount());
	}

	pushState(data: any, title: string, url?: string | null) {
		window.history.pushState(data, title, url);
		this.historySubject.next({
			state: {
				data: data,
				title: title,
				url: url
			}
		} as any);

	}

	replaceState(data: any, title: string, url?: string | null) {
		window.history.replaceState(data, title, url);
		this.historySubject.next({
			state: {
				data: data,
				title: title,
				url: url
			}
		} as any);
	}
}