import Vue from "vue";

export function formatBonusPoints(points: number) {
	const value = points || 0;

	let sign = '';
	switch (true) {
		case value > 0:
			sign = '+';
			break;
		case value < 0:
			sign = '-';
			break;
	}
	return `${sign}${value.toString(10)}`;
}

Vue.filter('formatBonusPoints', formatBonusPoints);
