
import { formatLabelKey, translate } from "@/filters";
import { Product } from "@/model/product";
import { Util } from "@/util/util";
import Vue from "vue";
import { translateProductFilterOption } from "@/filters/format-product-spec";

function render3pmsf(element: HTMLElement, columnValue: string) {
  element.innerHTML = "";

  if (columnValue == "1") {
    element.innerHTML = `<i class="icon-check" title="${translate("product.specs.boolean.1")}"></i>`;
    return;
  }

  element.innerHTML = `<i class="icon-close c-gray-light" title="${translate("product.specs.boolean.0")}"></i>`;
}

function renderStarRating(element: HTMLElement, columnValue: string) {
  element.innerHTML = "";

  let i = 0;
  while (i < columnValue.length) {
    element.appendChild(`<i class=\"icon-star\"></i>` as any);
    i++;
  }
}

function renderIcon(element: HTMLElement, columnValue: string, columnName: string = '') {
  element.innerHTML = "";

  const formattedIcon = formatLabelKey(columnValue);
  const config = Util.getConfig();
  let alt = columnValue;
  if (formattedIcon.length <= 0) {
    return;
  }

  if (columnName && columnName !== "") {
    const i18nSpecKey = `product.specs.${columnName}.options.${formattedIcon}`;
    alt = translate(i18nSpecKey);
  }

  element.innerHTML = `
			<img
				alt="${alt}"
				title="${alt}"
				class="spec-icon ${formattedIcon}"
				src="${config.resourcePath}/dist/img/specs/${formattedIcon}.svg"
			/>
		`;
}

function renderLabel(element: HTMLElement, columnName: string, columnValue: string, productLink: string, productDescription: string) {
  element.innerHTML = "";
  const translatedProductSpec = translateProductFilterOption(columnName, columnValue);
  if (translatedProductSpec !== columnValue) {
    element.innerHTML = `<a href="${productLink}" title="${productDescription}">${translatedProductSpec}</a>`;
  } else {
    if ('0' == columnValue && columnName != 'etValue') {
      element.innerHTML = `<a href="${productLink}" title="${productDescription}"></a>`
    } else {
      element.innerHTML = `<a href="${productLink}" title="${productDescription}">${columnValue}</a>`;
    }
  }
}

export default Vue.directive("product-spec-render", {
  bind(element: HTMLElement, binding: { value?: { column: string, product: Product } }) {
    const value = binding.value;
    if (value == undefined) {
      throw new Error("Product spec renderer expects the binding value not to be null/undefined");
    }

    if ("ral_version" == value.column) { // dynamic ral/version column based upon material of the truck rim
      const material = (<any>value.product)['material'];
      const dynamicColumn: string = (material && 'aluminium' == material.toString().toLowerCase())
          ? 'version'
          : 'ral';
      return renderLabel(element, dynamicColumn, (<any>value.product)[dynamicColumn],
          value.product.getUrl(), value.product.getLocalizedDescription());
    }

    const columnName = value.column;
    const columnValue = (<any>value.product)[columnName];
    const productLink = value.product.getUrl();
    const productDescription = value.product.getLocalizedDescription();

    switch (columnName) {
      case "3pmsf":
        render3pmsf(element, columnValue);
        break;
      case "starrating":
        renderStarRating(element, columnValue);
        break;
      case "axlPosition":
      case "bu":
        renderIcon(element, columnValue, columnName);
        break;
      case "usage":
        if (columnValue === "On / off") {
          renderLabel(element, columnName, columnValue, productLink, productDescription);
          break;
        }
        renderIcon(element, columnValue, columnName);
        break;
      case "3pmsfIcon":
        renderIcon(element, "ice");
        break;
      default:
        if ('number' == typeof columnValue) {
          if (columnValue > 0 || columnName == 'etValue') { // ET can be negative, zero or positive!
            renderLabel(element, columnName, columnValue.toString(), productLink, productDescription);
          } else {
            renderLabel(element, columnName, '', productLink, productDescription);
          }
        } else {
          renderLabel(element, columnName, columnValue, productLink, productDescription);
        }
        break;
    }
  }
});
