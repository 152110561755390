import _Vue from "vue";

//TODO: Rewrite jQuery
export function ShowMore(Vue: typeof _Vue, options?: ShowMoreOptions): void {
	$(function () {
		const _showMore = {
			showMore: $('.show-more'),
			trigger: 'h3.trigger',

			init: function () {
				_showMore.showMore.each(function () {
					const $this = $(this);
					const $trigger = $this.find(_showMore.trigger);
					const more = $trigger.data('more');
					const less = $trigger.data('less');

					const $contentBox = $this.children('.js-show-more-content');

					$trigger.on('click', function () {
						if ($this.hasClass('expanded')) {
							$contentBox.slideUp('fast', function () {
								$this.removeClass('expanded');
								$trigger.html(more);
							});
						} else {
							$contentBox.slideDown('fast', function () {
								$this.addClass('expanded');
								$trigger.html(less);
							});
						}
					});
				});
			},
		};

		_showMore.init();
	});

}

export class ShowMoreOptions {
}
