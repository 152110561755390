import _Vue from "vue";
import {Util} from "@/util/util";

//TODO: Rewrite jQuery
export function UserMenu(Vue: typeof _Vue, options?: UserMenuOptions): void {
	$(function () {
		var userMenu = $('.user-menu');
		var loggedInUser = userMenu.find('.logged-in-user');

		$('.login .user').on('click', function (e) {
			var $parent = $(this).parents('.login');

			e.stopPropagation();

			if (!$parent.hasClass('show')) {
				if (Util.isMobile()) {
					$('body').removeClass('nav-expanded');
				}

				$parent.addClass('show');

				// Fetch customers
				$.get(Util.getConfig().restBasePath.data + '/customers', function (response) {
					if (response.length > 0) {
						var currentUser = loggedInUser.data('current');
						var changeCompany = userMenu.find('select');

						response.forEach(function (customer:any) {
							var selected = '';

							if (customer.customerNumber == currentUser) {
								selected = ' selected';
							}
							changeCompany.append(
								'<option value="' +
								customer.customerNumber +
								'" ' +
								selected +
								'>' +
								customer.customerNumber +
								' - ' +
								customer.customerName +
								'</option>'
							);
						});

						setTimeout(function () {
							loggedInUser.addClass('show');
						}, 300);

						// loggedInUser.append(changeCompany);
						changeCompany.on('change', function () {
							loggedInUser.find('form').submit();
						});
					} else {
						setTimeout(function () {
							loggedInUser.addClass('single');
						}, 300);
					}
				})
					.done(function () {
					})
					.fail(function () {
					});
			} else {
				$parent.removeClass('show');
			}
		});

		$('.login .subnav-back').on('click', function (e) {
			e.stopPropagation();
			$('.login').removeClass('show');
		});
	});
}

export class UserMenuOptions {
}
