import { EcoTax } from "@/model/eco-tax";
import {
	ProductStock, ProductStockQueuedRequest, ProductStockServerResponse, ProductStockServerResponseMap
} from "@/model/product-stock";
import { LocalStorage } from "@/service/local-storage";
import { Api } from "@/util/api";
import { Subject } from "rxjs";
import { buffer, debounceTime } from "rxjs/operators";
import {ArticlePrice, ProductPriceStock, WarehouseStock} from "@/model/product-price-stock";

const PRODUCT_STOCK_CACHE_EXPIRATION_DURATION = (5 * 60 * 1000);

export class ProductStockCollection {
	private static className = "ProductStockCollection";
	private static instance: ProductStockCollection;
	private productStockServerResponseMap: ProductStockServerResponseMap = new Map();
	private productStockPromises: Map<string, Promise<ProductStockServerResponse>> = new Map();
	private productStockServerRequests = new Subject<ProductStockQueuedRequest>();

	private constructor(private api: Api, private localStorage: LocalStorage) {
		this.hydrateProductStockFromStorage();

		// debounce any stock price requests by 10 ms to prevent to many calls from being send.
		this.productStockServerRequests.pipe(buffer(this.productStockServerRequests.pipe(debounceTime(10))))
			.subscribe(this.getMultiplePricesAndStocks.bind(this));
	}

	static getInstance(): ProductStockCollection {
		if (ProductStockCollection.instance == null) {
			ProductStockCollection.instance = new ProductStockCollection(
				Api.getInstance(),
				LocalStorage.getInstance()
			);
		}
		return ProductStockCollection.instance;
	}

	getComplete(productKey: string): Promise<ProductStockServerResponse> {
		if (this.hasProductStock(productKey)) {
			// console.debug(
			// 	"Returning price and stocks from memory",
			// 	Object.assign({productKey: productKey}, this.getProductStock(productKey))
			// );
			return Promise.resolve(this.getProductStock(productKey));
		}
		let productStockPromise: Promise<ProductStockServerResponse>;
		if (this.hasProductStockRequestInFlight(productKey)) {
			//console.debug("Returning price and stocks from existing in flight request", {productKey: productKey});
			productStockPromise = this.getProductStockRequestInFlight(productKey);
		} else {
			//console.debug("Starting new request for price and stocks", {productKey: productKey});
			productStockPromise = this.startRequest(productKey);
		}
		return productStockPromise;
	}

	getPrice(productKey: string): Promise<number> {
		return this.getComplete(productKey).then(response => {
			if (response.pps.itemBaseNetPrice) {
				return response.pps.itemBaseNetPrice;
			}
			return -1;
		});
	}

	getPriceOnRequest(productKey: string): Promise<boolean> {
		return this.getComplete(productKey).then(response => {
			if (response.pps.priceOnRequest) {
				return response.pps.priceOnRequest;
			}
			return false;
		});
	}

	getStock(productKey: string): Promise<number> {
		return this.getComplete(productKey).then(response => response.pps.totalStock);
	}

	getStockForWarehouse(productKey: string): Promise<WarehouseStock[]> {
		return this.getComplete(productKey).then(response => response.pps.stocks);
	}

	getEcoTax(productKey: string): Promise<ArticlePrice | undefined> {
		return this.getComplete(productKey).then(response => response.pps.ecotax);
	}

	private hasProductStock(productKey: string): boolean {
		const productStock = this.productStockServerResponseMap.get(productKey);
		if (productStock == null) {
			return false;
		}

		if (Date.now() > productStock.$expirationTime) {
			this.removeProductStock(productStock.articleCode);
			return false;
		}

		return true;
	}

	private getProductStock(productKey: string): ProductStockServerResponse {
		const response = this.productStockServerResponseMap.get(productKey);
		if (response == null) {
			throw new Error("ProductKey not found in product stocks");
		}
		return response;
	}

	private getMultiplePricesAndStocks(requests: ProductStockQueuedRequest[]): void {
		const articleCodes = requests.map(request => request.articleCode);
		//console.debug("Retrieving multiple product stocks and prices", articleCodes);

		this.api.getPriceForProducts(articleCodes)
			.then((productStockPrices: any[]) => {
				productStockPrices
					.map(this.serializeServerProductPriceResponse.bind(this))
					.forEach((response: ProductStockServerResponse) => {
						this.storeProductStock(response);
						this.replyToSingleProductPriceRequest(requests, response);
					});
			})
			.catch(error => {
				console.error("Error occurred during the retrieval of multiple prices and stocks", {
					location: ProductStockCollection.className,
					error: error
				});
				requests.forEach(request => request.reject(error));
			});
	}

	private replyToSingleProductPriceRequest(requests: ProductStockQueuedRequest[],
		response: ProductStockServerResponse
	) {
		const originalRequest = requests.find(request => request.articleCode === response.articleCode);
		if (originalRequest == null) {
			console.error("Unable to find original request", {
				location: ProductStockCollection.className,
				requests: requests,
				response: response
			});
			return;
		}

		originalRequest.resolve(response);
	}

	private serializeServerProductPriceResponse(response: any): ProductStockServerResponse {
		const expirationTime = response.$expirationTime || this.getProductStockStorageExpirationTime();
		// into storage from raw pps response
		return {
			$expirationTime: expirationTime,
			articleCode: response.articleCode,
			pps: ProductPriceStock.createFromJson(response)
		};
	}


	private deserializeServerProductPriceResponse(response: any): ProductStockServerResponse {
		const expirationTime = response.$expirationTime || this.getProductStockStorageExpirationTime();
		// from storage
		return  {
			$expirationTime: expirationTime,
			articleCode: response.articleCode,
			pps: ProductPriceStock.createFromJson(response.pps)
		};
	}

	private getPriceAndStock(articleCode: string): Promise<ProductStockServerResponse> {
		return new Promise<ProductStockServerResponse>((resolve, reject) => {
			this.productStockServerRequests.next({
				articleCode: articleCode,
				resolve: resolve.bind(this),
				reject: reject.bind(this)
			});
		});
	}

	private startRequest(productKey: string): Promise<ProductStockServerResponse> {
		const promise = this.getPriceAndStock(productKey);
		this.productStockPromises.set(productKey, promise);
		return promise;
	}

	private hasProductStockRequestInFlight(productKey: string): boolean {
		return this.productStockPromises.has(productKey);
	}

	private getProductStockRequestInFlight(productKey: string): Promise<ProductStockServerResponse> {
		const promise = this.productStockPromises.get(productKey);
		if (promise == null) {
			throw new Error("ProductKey not found in product stock promises");
		}
		return promise;
	}

	private getProductStockStorageExpirationTime(): number {
		return Date.now() + PRODUCT_STOCK_CACHE_EXPIRATION_DURATION;
	}

	private storeProductStock(response: ProductStockServerResponse) {
		this.productStockServerResponseMap.set(response.articleCode, response);
		this.saveProductStockToStorage();
	}

	private removeProductStock(articleCode: string) {
		this.productStockServerResponseMap.delete(articleCode);
		this.saveProductStockToStorage();
	}

	private saveProductStockToStorage() {
		// used new key "pps" due to different format (old key was "productsPriceStock")
		this.localStorage.set("pps", Array.from(this.productStockServerResponseMap));
	}

	private hydrateProductStockFromStorage() {
		// storage is a json stringified map meaning [ ["1", {}], ... ] is the internal structure.
		const currentStorage = this.localStorage.get("pps") || []; // used new key "pps" due to different format
		currentStorage.forEach(([articleCode, productStock]: [any, any]) => {
			const response = this.deserializeServerProductPriceResponse(productStock);
			this.productStockServerResponseMap.set(articleCode, response);
		});
	}
}
