// IE11 fixes
import "core-js";
import "regenerator-runtime/runtime";
import "url-search-params-polyfill";
import "intersection-observer";
// END IE11 fixes
require("./scss/theme.scss");

import { LocalStorage } from "@/service/local-storage";
import Vue from "vue";
import store from "@/store/store";
import { CategorySelector } from "@/plugins/categorySelector";
import { ExpandableBox } from "@/plugins/expandableBox";
import { FiltersSm } from "@/plugins/filtersSm";
import { Footer } from "@/plugins/footer";
import { HideOrderButton } from "@/plugins/hideOrderButton";
import { Navigation } from "@/plugins/navigation";
import { ShowMore } from "@/plugins/showMore";
import { StyleGuide } from "@/plugins/styleguide";
import { TableResponsive } from "@/plugins/tableResponsive";
import { UserMenu } from "@/plugins/userMenu";
import { MouseEventPolyfill } from "@/plugins/mouseEventPolyfill";
import { Maps } from "@/plugins/maps";
import { VTooltip } from "v-tooltip";

export * from "./filters";
export * from "./directive";
Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.directive("tooltip", VTooltip);

// Load all the stand alone (jQuery :() scripts
Vue.use(CategorySelector);
Vue.use(ExpandableBox);
Vue.use(FiltersSm);
Vue.use(Footer);
Vue.use(HideOrderButton);
Vue.use(Navigation);
Vue.use(ShowMore);
Vue.use(StyleGuide);
Vue.use(TableResponsive);
Vue.use(UserMenu);
Vue.use(MouseEventPolyfill);
Vue.use(Maps);

console.debug("Vue is initializing");
window.isNewVue = true;

// Local storage is initialized as soon as possible
LocalStorage.getInstance();

new Vue({
    store: store,
    comments: true,
    data() {
        return <
            {
                vueInitialized: boolean;
            }
            >{
            vueInitialized: false
        };
    },
    components: {
        // GLOBAL COMPONENTS
        "domain-switcher": () => import("./components/domain-switcher/DomainSwitcher.vue"),
        "input-checkbox": () => import("@/components/input-checkbox/InputCheckbox.vue"),
        "input-checkbox-multiple": () => import("@/components/input-checkbox-multiple/InputCheckboxMultiple.vue"),
        "input-field": () => import("@/components/input-field/InputField.vue"),
        "textarea-field": () => import("@/components/textarea-field/TextAreaField.vue"),
        "select-field": () => import("@/components/select-field/SelectField.vue"),
        "selector": () => import("@/components/selector/Selector.vue"),
        "view-selector": () => import("@/components/view-selector/ViewSelector.vue"),
        "shopping-cart-icon": () => import("@/components/shopping-cart-icon/ShoppingCartIcon.vue"),
        "toast-panel": () => import("@/components/toast-panel/ToastPanel.vue"),
        "marketing-banner": () => import("@/components/marketing-banner/MarketingBanner.vue"),
        "product-search-wrapper": () => import("@/components/product-search-wrapper/ProductSearchWrapper.vue"),
        "suggested-products": () => import("@/components/suggested-products/SuggestedProducts.vue"),
        "slider": () => import("@/components/slider/Slider.vue"),

        // CONTROLLERS
        "product-detail-controller": () => import("./controller/product-detail/ProductDetail.vue"),
        "product-detail-controller-v2": () => import("./controller/product-detail/ProductDetailV2.vue"),
        "product-category-controller": () => import("./controller/product-category/ProductCategory.vue"),
        "default-page-controller": () => import("./controller/default-page/DefaultPage.vue"),
        "shopping-cart-controller": () => import("./controller/shopping-cart/ShoppingCart.vue"),
        "profile-controller": () => import("./controller/profile/Profile.vue"),
        "profile-edit-controller": () => import("./controller/profile-edit/ProfileEdit.vue"),
        "contact-form-controller": () => import("./controller/contact-form/ContactForm.vue"),
        "register-form-controller": () => import("./controller/register-form/RegisterForm.vue"),
        "cookiebar-controller": () => import("./controller/cookiebar/Cookiebar.vue"),
        "claim-controller": () => import("./controller/claim/Claim.vue"),
        "seo-filter-controller": () => import("./controller/seo-filter/SeoFilter.vue"),
        "conference-form-controller": () => import("./controller/conference-form/ConferenceForm.vue"),

        // GCL Components -> Will be moved to Global Component Library
        "counter": () => import("./components/gcl/Counter.vue"),

        // V2 components
        "environment-switcher": () => import("./components/v2/EnvironmentSwitcher.vue"),
        "input-field-v2": () => import("@/components/v2/fields/InputField.vue"),
        "pdf-download-link-v2": () =>
            import("./components/v2/pdf-download/PdfDownloadLinkV2.vue"),
        "pill-with-label": () =>
            import("./components/v2/PillWithLabel.vue"),
        "product-image-with-thumbnails": () =>
            import("./components/v2/product-detail/ProductImageWithThumbnails.vue"),
        "product-specification-tabs": () =>
            import("./components/v2/product-detail/ProductSpecificationTabs.vue"),
        "product-specification-tab": () =>
            import("./components/v2/product-detail/ProductSpecificationTab.vue"),
        "expendable-box-v2": () =>
            import("./components/v2/ExpandableBox.vue"),
        "order-button-v2": () => import("./components/v2/order-button/OrderButton.vue"),
        "app-slider": () => import("@/components/v2/slider/Slider.vue"),
        "swiper-slide": () => import("@/components/v2/slider/SwiperSlide.vue"),
        "mobile-desktop-switch": () => import("@/components/v2/mobile-desktop-switch/MobileDesktopSwitch.vue"),
		"product-shelf": () => import("@/components/v2/product-list/ProductShelf.vue"),
		"alternative-products": () => import("@/components/v2/AlternativeProducts.vue"),
		"orderable-product-base": () => import("@/components/v2/orderable-product/OrderableProductBase.vue"),
		"orderable-product-card": () => import("@/components/v2/orderable-product/OrderableProductCard.vue"),
    },
    created() {
        console.debug("Vue has been initialized");
    }
}).$mount("#app");
