import {Product} from '@/model/product';
import {ShoppingCartPrice} from '@/model/shopping-cart';
import {Util} from '@/util/util';
import {ProductPriceStock} from "@/model/product-price-stock";
import {ConvertedGtmProduct} from "@/model/converted-gtm-product";
import {GtmEnhancedEcommerceType} from "@/model/gtm-enhanced-ecommerce-type";
import {RegisterForm} from "@/model/forms";

export class GTMBroker {
    private static instance: GTMBroker;
    private readonly _dataLayer = {
        push: function (action: any) {
            console.warn('GTM dataLayer not available', {
                location: 'gtmMixin',
                arguments: arguments
            });

            // handle callbacks preventing weird problems within the application
            if (action.eventCallback != null) {
                action.eventCallback();
            }
        }
    };

    private constructor() {
        const dataLayer = Util.getGTMDataLayer();
        if (dataLayer) {
            this._dataLayer = dataLayer;
        }
    }

    static getInstance(): GTMBroker {
        if (GTMBroker.instance == null) {
            GTMBroker.instance = new GTMBroker();
        }
        return GTMBroker.instance;
    }

    productsViewed(products: any[], gtmEnhancedEcommerceType: string) {
        if (gtmEnhancedEcommerceType == GtmEnhancedEcommerceType.NONE) {
            return;
        }
        const impressions : any = [];
        let position = 1;
        products.forEach(
            p => {
                impressions.push(
                    {
                        name: p.description,
                        id : p.key,
                        brand : p.brand,
                        category : p.bu,
                        list: gtmEnhancedEcommerceType,
                        position: position++,
                        variant: p.productCategory || 'Heuver',
                    }
                );
            }
        );

        const action = {
            event: 'productView',
            ecommerce: {
                currencyCode: 'EUR',
                impressions: impressions
            }
        };

        this._dataLayer.push(action);

        // console.debug('Products viewed (enhanced ecommerce impression)', {
        //     location: 'GTMBroker#productsViewed',
        //     dataLayer: this._dataLayer,
        //     action: action
        // });
    };

    /**
     * Method which is called on the product detail page, when a user is viewing one specific product.
     */
    productDetailViewed(product: Product) {
        const action = {
            ecommerce: {
                detail: {
                    products: [
                        {
                            name: product.description,
                            id: product.key,
                            brand: product.brand,
                            category : product.bu,
                            variant: product.productCategory || 'Heuver',
                        }
                    ]
                }
            }
        };

        this._dataLayer.push(action);

        console.debug('Product detail viewed', {
            location: 'GTMBroker#productDetailViewed',
            dataLayer: this._dataLayer,
            action: action
        });
    };

    /**
     * Method which is called when a product gets clicked on within a list for example. This can
     * optionally be provided with a position (the position of the element within a list) and a
     * location e.g. the name of the list in which this product was displayed.
     */
    productClicked(product: Product, callBack: () => void, position: number, location: string) {
        if (position == null) {
            position = 0;
        }

        const ecommerce = {
            click: {
                actionField: {},
                products: [
                    {
                        name: product.description,
                        id: product.key,
                        brand: product.brand,
                        category : product.bu,
                        variant: product.productCategory || 'Heuver',
                        position: position
                    }
                ]
            }
        };

        if (location != null && location.length > 0) {
            ecommerce.click.actionField = {list: location};
        }

        const action = <any>{
            event: 'productClick',
            ecommerce: ecommerce,
            eventCallback: undefined,
            eventTimeout: undefined
        };

        if (callBack != null) {
            action.eventCallback = callBack;
            action.eventTimeout = 100;
        }

        this._dataLayer.push(action);

        console.debug('Product clicked', {
            location: 'GTMBroker#productClicked',
            dataLayer: this._dataLayer,
            action: action
        });
    };

    /**
     * Method which is called when a product with an amount and quantity is added to the shopping cart.
     */
    productAddedToCart(product: Product, quantity: number) {
        product.getPrice().then(price => {
            const action = {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'EUR',
                    add: {
                        products: [
                            {
                                name: product.description,
                                id: product.key,
                                brand: product.brand,
                                category : product.bu,
                                variant: product.productCategory || 'Heuver',
                                price: (price || 0).toFixed(2),
                                quantity: quantity
                            }
                        ]
                    }
                }
            };

            this._dataLayer.push(action);

            console.debug('Product added to chart', {
                location: 'GTMBroker#productAddedToCart',
                dataLayer: this._dataLayer,
                action: action
            });
        });
    };

    /**
     * Method which is called when a user goes from one step to the next within the shopping cart.
     *
     * @param shoppingCartPrice
     * @param {number} step
     */
    proceededToNextStepOfOrder(shoppingCartPrice: ShoppingCartPrice, step: number) {
        const gtmProducts = Object.keys(shoppingCartPrice.products).map((key) => {
            return this.convertProductForGTM(shoppingCartPrice.products[key]);
        });

        this._dataLayer.push({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: step,
                        option: 'unknown',
                    },
                    products: gtmProducts,
                },
            },
        });
    };

    /**
     * Method which converts products from our internal structure into a structure required by the
     * enhanced e-commerce guidelines from google, for the use in GTM.
     *
     * @return {{id: string, name: string, brand: string, category: string, variant: string, price: number, quantity: number }}
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
     * @private
     * @param pps
     */
    private convertProductForGTM(pps: ProductPriceStock): ConvertedGtmProduct {
        return {
            id: pps.articleCode,
            name: pps.productInfo ? pps.productInfo.description : '',
            brand: pps.productInfo ? pps.productInfo.brand : '',
            category: pps.productInfo ? pps.productInfo.businessUnit : '',
            variant: pps.productInfo ? pps.productInfo.category : 'Heuver',
            price: (pps.itemNetPrice || 0).toFixed(2),
            quantity: pps.quantity,
        };
    };

    /**
     * Method which is called when a product is removed from the shopping cart.
     *
     * @param {{key: string, description: string, brand: string, productCategory: string}} product
     * @param {number} price
     * @param {number} quantity
     */
    productRemovedFromCart(pps: ProductPriceStock) {
        this._dataLayer.push({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: 'EUR',
                remove: {
                    products: [
                        {
                            name: pps.productInfo ? pps.productInfo.description : pps.articleCode,
                            id: pps.articleCode,
                            brand: pps.productInfo ? pps.productInfo.brand : '',
                            category: pps.productInfo ? pps.productInfo.businessUnit : '',
                            variant: pps.productInfo ? pps.productInfo.category : 'Heuver',
                            price: (pps.itemNetPrice || 0).toFixed(2),
                            quantity: pps.quantity,
                        },
                    ],
                },
            },
        });
    };


    /**
     * Method with which the GTM is informed about a successful order confirmation.
     *
     * @param {string} salesNo
     * @param shoppingCartPrice
     */
    orderConfirmed(salesNo: string, shoppingCartPrice: ShoppingCartPrice) {
        const gtmProducts = Object.keys(shoppingCartPrice.products).map(key => {
            return this.convertProductForGTM(shoppingCartPrice.products[key]);
        });

        let cookies = document.cookie.split(";");
        let gclClickId;
        let gclClickDate
        for(let cookie of cookies){
            cookie = cookie.trim()
            let key = cookie.split("=")[0];
            if(key === "_gcl_cookie"){
                gclClickId = cookie.split("=")[1].split(".")[2];
                gclClickDate = cookie.split("=")[1].split(".")[1];
            }
        }

        let gclObject = {};
        Object.assign(
            gclObject,
            gclClickId ? { GoogleClickID: gclClickId } : null,
            gclClickDate ? { GoogleClickIDDate: gclClickDate } : null
        );

        this._dataLayer.push({
            event: 'purchase',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: salesNo,
                        affiliation: 'Online Store',
                        revenue: (shoppingCartPrice.subTotal || 0).toFixed(2), // TODO: Total or Subtotal?
                        tax: '',
                        shipping: (shoppingCartPrice.transportationCosts || 0).toFixed(2),
                        coupon: '',
                        couponDiscount: (shoppingCartPrice.discount || 0).toFixed(2),
                    },
                    products: gtmProducts,
                },
            },
            gclObject
        });
    };

    contactForm() {
        this._dataLayer.push({
            event: 'contactForm',
            success: 'true',
        });
    };

    /**
     * Method with which the GTM is informed when the registration form is successfully sent
     *
     * @param fields: {resellerSector: string, nonResellerSector: string, customerNumber: string, interests: string}
     */
    registerForm(fields: RegisterForm) {
        let registerFormObject = {};
        let cookies = document.cookie.split(";");
        let gclClickId;
        let gclClickDate;
        for(let cookie of cookies){
            cookie = cookie.trim()
            let key = cookie.split("=")[0];
            if(key === "_gcl_cookie"){
                gclClickId = cookie.split("=")[1].split(".")[2];
                gclClickDate = cookie.split("=")[1].split(".")[1];
            }
        }
        Object.assign(
            registerFormObject,
            { event: 'register' },
            { success: 'true' },
            fields.Klantgroep_staging ? { Klantgroep_staging: fields.Klantgroep_staging } : null,
            fields.ActiveSegment ? { ActiveSegment: fields.ActiveSegment } : null,
            Util.getConfig().customerNumber ? { customerNumber: Util.getConfig().customerNumber } : null,
            gclClickId ? { GoogleClickID: gclClickId } : null,
            gclClickDate ? { GoogleClickIDDate: gclClickDate } : null
        );

        this._dataLayer.push(registerFormObject);
    }

    loggedOutPageView() {
        this._dataLayer.push({
            login: 'logged-out',
        });
    };

    // Compiler Error: Duplicate function implementation
    // @ts-ignore
    loggedInPageView (userId: string) {
        this._dataLayer.push({
            login: 'logged-in',
            userId: userId,
        });
    };

    // Compiler Error: Duplicate function implementation
    // @ts-ignore
    loggedInPageView (userId: string, customerId: string) {
        this._dataLayer.push({
            login: 'logged-in',
            userId: userId,
            klantId: customerId,
        });
    };
}

// Set gtmBroker on window for the body end scripts
declare global {
    interface Window {
    	gtmBroker: GTMBroker;
    }
}

const gtmBroker = GTMBroker.getInstance();
window.gtmBroker = gtmBroker;
