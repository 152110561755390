import _Vue from "vue";

//TODO: Rewrite jQuery
export function ExpandableBox(Vue: typeof _Vue, options?: ExpandableBoxOptions): void {
	$(function() {
		var _expandableBox = {
			expandableBox: $('.expandable-box'),
			trigger: 'h2',

			init: function() {
				$('.expandable-box').each(function() {
					var $this = $(this);
					var $contentBox = $this.children('.js-box-content');

					$this.find(_expandableBox.trigger).on('click', function() {
						if ($this.hasClass('expanded')) {
							$contentBox.slideUp('fast', function() {
								$this.removeClass('expanded');
							});
						} else {
							$contentBox.slideDown('fast', function() {
								$this.addClass('expanded');
							});
						}
					});
				});
			},
		};

		setTimeout(() => _expandableBox.init(), 150);
	});
}

export class ExpandableBoxOptions {
}
