import {EcoTax} from '@/model/eco-tax';
import {ProductStock, ProductStockServerResponse} from '@/model/product-stock';
import {ProductStockCollection} from '@/service/product-stock';
import {ShoppingCartCollection} from '@/service/shopping-cart';
import {Util} from '@/util/util';
import {ArticlePrice, WarehouseStock} from "@/model/product-price-stock";

export type ProductMap = Map<string, Product>;

export interface i18nConfig {
    [key: string]: { [key: string]: string }
}


export class Product {
    key: string = '';
    '3pmsf': number = 0;
    actualWidth: number = 0;
    airPressure: number = 0;
    aspectRatio: number = 0;
    axlPosition: string = '';
    boltHoles: number = 0;
    boltHoleType: string = '';
    ventHoles: number = 0;
    brand: string = '';
    bu: string = '';
    carcassConstruction: string = '';
    colour: string = '';
    deMinimis13: string = '';
    deMinimis19: string = '';
    description: string = '';
    diameter: number = 0;
    diameterCenterHole: number = 0;
    diameterPitchCircle: number = 0;
    eanCode: string = '';
    etValue: string = '';
    fuelEfficiency: string = '';
    grossPrice: number = 0;
    i18n: i18nConfig = {};
    itemCondition: string = '';
    loadCapacity1: string = '';
    loadCapacity2: string = '';
    loadCapacity3: string = '';
    loadCapacity4: string = '';
    loadCapacityDual: string = '';
    loadCapacitySingle: string = '';
    loadIndex: string = '';
    loadIndex1: string = '';
    loadIndex2: string = '';
    loadIndex3: string = '';
    loadIndex4: string = '';
    loadSpeedIndexTotal: string = '';
    loadedRadius: number = 0;
    matchCode: string = '';
    mudSnow: string = '';
    multipleSalesQuantity: number = 0;
    onHand: number = 0;
    permittedRim: string = '';
    plyRating: string = '';
    productCategory: string = '';
    productImage: string = '';
    profile: string = '';
    radialDiagonal: string = '';
    recommendedRim: string = '';
    rimInch: number = 0;
    rimWidth: string = '';
    rollingCircumference: number = 0;
    season: string = '';
    showItem: number = 0;
    showOnline: boolean = true;
    size: string = '';
    soundDb: number = 0;
    soundWave: string = '';
    speed: string = '';
    speedIndex: string = '';
    speedIndex1: string = '';
    speedIndex2: string = '';
    speedLimit1: string = '';
    speedLimit2: string = '';
    starRating: string = '';
    tlTt: string = '';
    traCode: string = '';
    tread: number = 0;
    tubeType: string = '';
    usage: string = '';
    vehicleClass: string = '';
    weight: number = 0;
    wetGrip: string = '';
    width: number = 0;
    price: number = -1;
    IFVF: string = '';
    valveIndication: string = '';
    vehicleType: string = '';
    includeInnerTire: string = '';
    valveProtector: string = '';
    reinforcementRing: string = '';
    fittedOnWheel: string = '';
    speedSingle: string = '';
    speedDual: string = '';
    wheelType: string = '';
    material: string = '';
    ral: string = '';
    version: string = '';

    constructor(data: { [key: string]: any }) {
        Object.keys(data)
            .filter(key => data[key] != null && (<any>this)[key] != null)
            .forEach(key => (<any>this)[key] = data[key]);
    }

    /**
     * Indicates whether this product is orderable for given stock, price and price on request flag.
     *
     * @param totalStock    stock of this product.
     * @param productPrice    price of this product.
     * @param priceOnRequest true for products with price on request (no price available).
     */
    isOrderable(totalStock: number, productPrice: number | undefined, priceOnRequest: boolean | undefined): boolean {
        return (totalStock > 0) && ((true === priceOnRequest) || (undefined !== productPrice && productPrice > 0));
    }

    showPriceOnly(totalStock: number, productPrice: number | undefined, priceOnRequest: boolean | undefined): boolean {
        return (1 == this.showItem) && (undefined !== productPrice && productPrice > 0) && !this.isOrderable(
            totalStock, productPrice, priceOnRequest);
    }

    getShowGrossPrice(): boolean {
        return this.isUsed() && this.isTyre() && this.isAgricultural();
    }

    getPriceAndStocks(): Promise<ProductStockServerResponse> {
        return ProductStockCollection.getInstance().getComplete(this.getKey());
    }

    getEcoTax(): Promise<ArticlePrice | undefined> {
        return ProductStockCollection.getInstance().getEcoTax(this.getKey());
    }

    getPrice(): Promise<number> {
        return ProductStockCollection.getInstance().getPrice(this.getKey());
    }

    getStock(): Promise<number> {
        return ProductStockCollection.getInstance().getStock(this.getKey());
    }

    getStockForWarehouse(): Promise<WarehouseStock[]> {
        return ProductStockCollection.getInstance().getStockForWarehouse(this.getKey());
    }

    getPriceOnRequest(): Promise<boolean> {
        return ProductStockCollection.getInstance().getPriceOnRequest(this.getKey());
    }

    getKey(): string {
        return this.key;
    }

    getLowerKey(): string {
        return this.getKey().toLowerCase();
    }

    getUrl(): string {
        const slug = this.translateProperty('slug');
        return `${Util.getConfig().rootPath}/product/${encodeURIComponent(this.getLowerKey())}/${slug}`;
    }

    isBusinessUnit(businessUnit: string) {
        return Util.areBusinessUnitsEqual(this.bu, businessUnit);
    }

    isTruck() : boolean {
        if (!this.bu) {
            return false;
        }
        return "truck" === this.bu.toLowerCase();
    }

    isAgricultural() : boolean {
        if (!this.bu) {
            return false;
        }
        return "agricultural" === this.bu.toLowerCase();
    }

    isOTR() : boolean {
        if (!this.bu) {
            return false;
        }
        return "otr" === this.bu.toLowerCase();
    }

    isBrand(brand: string) {
        return Util.areBrandsEqual(this.brand, brand);
    }

    isCategory(category: string) {
        return Util.areCategoriesEqual(this.productCategory, category);
    }

    isTyre(): boolean {
        if (!this.productCategory) {
            return false;
        }
        return "banden" === this.productCategory.toLowerCase();
    }

    isRim(): boolean {
        if (!this.productCategory) {
            return false;
        }
        return "wielen" === this.productCategory.toLowerCase();
    }

    isUsed(): boolean {
        if (!this.itemCondition) {
            return false;
        }
        return "gebruikt" === this.itemCondition.toLowerCase();
    }

    /**
     * Get the image source for the given product in given size
     */
    getImageSrc(size: 'small' | 'medium' | 'large'): string {
        if (size == null) {
            size = 'small';
        }
        return `https://images.heuver.com/products/${this.key}.jpg?impolicy=${size}`;
    }

    /**
     * The fallback image if the regular image cannot be loaded.
     */
    imageIsUnAvailabeSrc(): string {
        return `${Util.getConfig().resourcePath}/dist/img/noimg.jpg`;
    }

    /**
     * Gets the image source set for the given product.
     */
    getImageSrcSet(): string {
        const small = this.getImageSrc('small');
        const medium = this.getImageSrc('medium');
        const large = this.getImageSrc('large');
        return `${small} 120w, ${medium} 274w, ${large} 800w`;
    }

    /**
     * Get the image sizes for all products.
     */
    getImageSizes(): string {
        return `(max-width: 499px) 200px, (min-width: 500px) and (max-width: 991px) 800px, (min-width: 992px) 80px`;
    }

    getLocalizedDescription(): string{
        return this.translateProperty("description");
    }

    private translateProperty(property: string) {
        const locale = Util.getConfig().locale;

        // Get (if present) translated value from product.i18n.[locale].[attr]
        if (locale in this.i18n && property in this.i18n[locale]) {
            return this.i18n[locale][property];
        }

        //We dont have the direct translation, so we'll try to return the english translation.
        if((property.toLowerCase() === "description" || property.toLowerCase() === "slug")  && (this.i18n["en"] && this.i18n["en"][property])){
            return this.i18n["en"][property];
        }

        // Get attribute directly from product (= fallback)
        if (property in this) {
            return (<any>this)[property];
        }
        return null;
    }


}

