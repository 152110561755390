
import Vue from "vue";
import { BrowserHistory } from "@/util/history";

export default Vue.directive("history-href", {
	bind(element: any, binding: any) {
		element.href = binding.value;

		element.clickListener = (event: MouseEvent) => {
			if (event == null || event.target == null) {
				return;
			}
			const element = event.target as any;
			event.stopImmediatePropagation();
			event.preventDefault();
			BrowserHistory.getInstance().pushState({}, "", element.href);
		};
		element.addEventListener("click", element.clickListener);
	},
	update(element: any, binding: any) {
		element.href = binding.value;
	},
	unbind(element: any) {
		element.removeEventListener("click", element.clickListener);
	}
});
